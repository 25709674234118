<template>
  <upload-ui v-model="model"></upload-ui>
</template>

<script>
import {defineComponent} from 'vue';
import step from '@/mixins/document-master/step';
import UploadUi from '@/components/ui/UploadUi.vue';
import {mapActions, mapState} from 'vuex';

export default defineComponent({
  name: 'StepFiles',
  components: {
    UploadUi,
  },
  mixins: [step],
  computed: {
    ...mapState('master/files', ['files']),
    model: {
      get() {
        return this.files;
      },
      set(files) {
        this.set(files);
      },
    },
  },
  methods: {
    ...mapActions('master/files', ['set']),
  },
});
</script>

<style scoped lang="scss">
.upload-ui {
  height: 100%;
}
</style>
