import StepLinks from '@/components/document-master/steps/StepLinks.vue';
import {StepCode} from '@/common/consts/document-master/common';

export default {
  code: StepCode.Links,
  title: 'Ссылки на документы',
  tooltip: 'Установка связей между документами помогает в работе с ними. Поиск можно осуществлять по содержанию или номерам',
  menuTitle: 'Ссылки',
  component: StepLinks,
};
