import {INITIAL_TYPE, TypeCode} from '@/common/consts/document-master/common';

export default {
  namespaced: true,
  state: {
    type: INITIAL_TYPE,
    project: null,
  },
  getters: {
    isDirty(state) {
      return state.type?.code !== TypeCode.Document || state.project !== null;
    },
    contentTitle(state) {
      return state.type.code === TypeCode.Document ? 'Создание документа' : `Создание "${state.type.label}"`;
    },
    showWarning(state) {
      const defaultProjectId = state.type.defaultProjectId;
      const projectId = state.project?.code;
      return defaultProjectId && defaultProjectId >= 0 && projectId && projectId !== defaultProjectId;
    },
    linkTypesIds(state) {
      return state.type.linkTypesIds || [];
    },
  },
  actions: {
    setType({state, commit}, payload) {
      if (state.type.code !== payload.code) {
        commit('setType', payload);
        commit('master/parameters/clear', null, {root: true});
        commit('master/links/clearLinkTypes', null, {root: true});
        commit('master/clients/clearLinkTypes', null, {root: true});
      }
    },
    setProject({state, commit}, payload) {
      if (state.project?.code !== payload?.code) {
        commit('setProject', payload);
      }
    },
  },
  mutations: {
    setType(state, payload) {
      state.type = payload;
    },
    setProject(state, payload) {
      state.project = payload;
    },
    clear(state) {
      state.type = INITIAL_TYPE;
      state.project = null;
    },
  },
};
