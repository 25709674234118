import {defineComponent} from 'vue';
import ExternalFile from '@/common/models/file/external-file';
import {instance} from '@/common/utils/props-validators';
import {BaseFile} from '@/common/models/file/base-file';
import {unionWith} from 'lodash-es';
import {WARN_NOTIFY_TYPE} from '@/configs/notifyTypes';

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:model-value',
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
      validator: instance(BaseFile),
    },
  },
  emits: ['update:model-value'],
  methods: {
    dispatchChange() {
      this.$refs.input.dispatchEvent(new MouseEvent('click'));
    },
    onChange(event) {
      const files = Array.from(event.target.files);

      if (files.length) {
        void this.add(files);
      }
    },
    async add(files) {
      this.beforeAdd?.();
      const newValues = await this.getUniqFiles(files);
      this.$emit('update:model-value', newValues);
      this.$refs.input.value = '';
      this.afterAdd?.();
    },
    remove(id) {
      const newValue = this.modelValue.filter(current => current.id !== id);
      this.$emit('update:model-value', newValue);
    },
    async getUniqFiles(raw) {
      const files = await ExternalFile.createList(raw);
      const uniq = unionWith(this.modelValue, files, (a, b) => a.name === b.name && a.size === b.size);
      const excludedCount = files.length + this.modelValue.length - uniq.length;

      if (excludedCount > 0) {
        this.$notify({
          title: 'Внимание!',
          type: WARN_NOTIFY_TYPE,
          text: excludedCount > 1 ? 'Файлы уже добавлены' : 'Файл уже добавлен',
        });
      }

      return uniq;
    },
  },
});
