export default {
  namespaced: true,
  state: {
    files: [],
  },
  actions: {
    set({commit, dispatch}, payload) {
      if (payload.length > 0) {
        dispatch('master/steps/enable', 'files', {root: true});
      }
      commit('set', payload);
    },
  },
  mutations: {
    set(state, payload) {
      state.files = payload;
    },
    clear(state) {
      state.files = [];
    },
  },
};
