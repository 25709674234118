import {Card} from '@/common/models/card/card';
import {DEFAULT_LINK_TYPE} from '@/services/api/document-master/document-master.consts';

export default class LinkCard extends Card {
  constructor(response, idKey, titleKey, icon, path, tableId) {
    super(response, idKey, titleKey, icon, 'Номер');

    this.href = `/${path}/${response[idKey]}`;
    this.linkType = DEFAULT_LINK_TYPE;
    this.isLinkTypeInitial = true;
    this.linkTypeTableId = tableId;
  }

  static resetLinkType(link) {
    return {
      ...link,
      linkType: DEFAULT_LINK_TYPE,
      isLinkTypeInitial: true,
    };
  }
}
