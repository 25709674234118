import Option from '@/common/models/option/option';

export default {
  namespaced: true,
  state: {
    parameters: [],
    model: {},
  },
  getters: {
    request(state) {
      if (!state.parameters.length) {
        return null;
      }

      return Object.values(state.model).map(item => item instanceof Option ? item.code : item);
    },
  },
  mutations: {
    setParameters(state, payload) {
      state.parameters = payload;
    },
    setModel(state, payload) {
      state.model = payload;
    },
    clear(state) {
      state.parameters = [];
      state.model = {};
    },
  },
};
