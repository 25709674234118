import StepClients from '@/components/document-master/steps/StepClients.vue';
import {StepCode} from '@/common/consts/document-master/common';

export default {
  code: StepCode.Clients,
  title: 'Ссылки на контрагентов',
  tooltip: 'Установка связей документа с контрагентами облегчит его поиск в будущем. Контрагента можно найти по названию или номеру',
  menuTitle: 'Контрагенты',
  component: StepClients,
};
