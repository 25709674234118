<template>
  <field-ui class="search-ui" :id="id" :label="label" :required="required" :error="errorMessage" :hint="hint">
    <dropdown-ui ref="dropdown" :offset="4" shrink full-width close-if-inner-click close-if-outside-click>
      <template #anchor>
        <input-ui
          v-if="field === 'input'"
          ref="control"
          :id="id"
          :placeholder="placeholder"
          :color="color"
          :size="size"
          :disabled="disabled"
          :error="!!error"
          :max-length="maxLength"
          :no-clear="noClear"
          autocomplete="off"
          :model-value="modelValue"
          @update:model-value="onInput($event)"
          @focus="onFocus"
          @blur="onBlur"
          @clear="onClear"
          @keydown="onKeydown"
        ></input-ui>

        <textarea-ui
          v-else
          ref="control"
          :id="id"
          :placeholder="placeholder"
          :disabled="disabled"
          :error="!!error"
          :max-length="maxLength"
          :rows="rows"
          autocomplete="off"
          :no-resize="noResize"
          :model-value="modelValue"
          @update:model-value="onInput($event)"
          @focus="onFocus"
          @blur="onBlur"
          @keydown="onKeydown"
        ></textarea-ui>
      </template>

      <dropdown-list-ui :options="filteredHints" @select="onSelect"></dropdown-list-ui>
    </dropdown-ui>
  </field-ui>
</template>

<script>
import {defineComponent} from 'vue';
import InputUi from '@/components/ui/InputUi.vue';
import field from '@/mixins/form/field';
import {only} from '@/common/utils/props-validators';
import {debounce, uniqueId} from 'lodash-es';
import FieldUi from '@/components/ui/FieldUi.vue';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import DropdownListUi from '@/components/ui/DropdownListUi.vue';
import {INPUT_DEBOUNCE} from '@/common/consts/control.consts.js';
import {GET_HINTS_COUNT} from '@/services/api/document/document-api.consts.ts';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import TextareaUi from '@/components/ui/TextareaUi.vue';
import {CanceledError} from 'axios';
import abort from '@/mixins/abort';
import {ErrorHelper} from '@/services/errorHelper';

export default defineComponent({
  name: 'PromptUi',
  mixins: [abort, field],
  components: {
    TextareaUi,
    DropdownListUi,
    DropdownUi,
    FieldUi,
    InputUi,
  },
  model: {
    prop: 'modelValue',
    event: 'update:model-value',
  },
  props: {
    modelValue: String,
    field: {
      type: String,
      default: 'input',
      validator: only('input', 'textarea'),
    },
    color: {
      type: String,
      default: 'gray',
      validator: only('gray', 'white'),
    },
    size: {
      type: String,
      default: 'm',
      validator: only('m', 'l'),
    },
    placeholder: {
      type: String,
      default: 'Введите значение',
    },
    maxLength: {
      type: [Number, null],
      default: null,
      validator: value => value === null || value > 0,
    },
    rows: {
      type: Number,
    },
    noClear: {
      type: Boolean,
      default: false,
    },
    noResize: {
      type: Boolean,
      default: false,
    },
    getHints: {
      type: Function,
      required: true,
    },
  },
  emits: ['update:model-value', 'focus', 'blur'],
  data() {
    return {
      id: uniqueId('prompt-ui-'),
      hints: [],
      isLoading: false,
      isAsync: null,
    };
  },
  computed: {
    isInit() {
      return this.isAsync !== null;
    },
    filteredHints() {
      if (this.isAsync || !this.modelValue) {
        return this.hints;
      }

      return this.hints.filter(hint => {
        const normalizedHint = this.normalize(hint.label);
        const normalizedCurrent = this.normalize(this.modelValue);
        return normalizedHint.includes(normalizedCurrent) && hint.label.trim() !== this.modelValue.trim();
      });
    },
  },
  methods: {
    async init() {
      if (this.isInit) {
        return;
      }

      try {
        this.isLoading = true;
        const hints = await this.getHints();

        this.isAsync = hints.length >= GET_HINTS_COUNT;

        if (!this.isAsync) {
          this.hints = hints;
        }
      } catch (error) {
        this.onError(error);
      } finally {
        this.isLoading = false;
      }
    },
    onInput(value, noOpen) {
      this.$emit('update:model-value', value);

      if (!this.isInit) {
        return;
      }

      if (this.isAsync) {
        this.getAsyncHints(value, noOpen);
      } else {
        this.getSyncHints(value);
      }
    },
    onFocus() {
      this.init();

      if (this.modelValue) {
        this.open();
      }

      this.$emit('focus');
    },
    onBlur() {
      this.$emit('blur');
    },
    onClear() {
      this.$emit('update:model-value', '');
    },
    onKeydown(event) {
      if (event.code === 'Tab') {
        this.close();
      }

      if ((event.metaKey || event.ctrlKey) && event.code === 'Space') {
        this.open();
      }
    },
    onSelect({option}) {
      this.close();
      this.onInput(option.label, true);
    },
    getAsyncHints(query, noOpen) {
      this.close();
      this.abort('Получено новое значение');
      void this.getHintsDebounced(query, noOpen);
    },
    getSyncHints(query) {
      if (query) {
        this.open();
      } else {
        this.close();
      }
    },
    getHintsDebounced: debounce(async function (query, noOpen) {
      try {
        this.isLoading = true;
        this.hints = await this.getHints(query, this.abortController.signal);
        this.isLoading = false;

        if (!noOpen && this.$refs.control.isFocused) {
          this.open();
        }
      } catch (error) {
        this.onError(error);
      }
    }, INPUT_DEBOUNCE),
    open() {
      if (!this.isLoading && this.filteredHints.length > 0) {
        this.$refs.dropdown.show();
      }
    },
    close() {
      this.$refs.dropdown.hide();
    },
    normalize(value) {
      return value.toLowerCase().replaceAll('ё', 'е');
    },
    onError(error) {
      if (error instanceof CanceledError) {
        return;
      }

      this.isLoading = false;

      this.$notify({
        title: `Ошибка получения списка часто используемых значений для поля "${this.label || this.placeholder}"`,
        type: ERROR_NOTIFY_TYPE,
        text: ErrorHelper.format(error),
      });
    },
  },
});
</script>
