<template>
  <transition name="opacity-replaced">
    <div v-show="isActive" class="tab-ui">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
import {defineComponent} from 'vue';
import {uniqueId} from 'lodash-es';

export default defineComponent({
  name: 'TabUi',
  inject: ['addTab', 'removeTab', 'activeId'],
  props: {
    title: {
      type: String,
      default: 'Неизвестная вкладка',
    },
  },
  data() {
    return {
      id: uniqueId('tab-ui-'),
    };
  },
  mounted() {
    this.addTab({
      id: this.id,
      title: this.title,
      ...this.$attrs,
    });
  },
  beforeDestroy() {
    this.removeTab(this.id);
  },
  computed: {
    isActive() {
      return this.id === this.activeId;
    },
  },
});
</script>

<style scoped lang="scss">
.tab-ui {
  width: 100%;
  top: 0;
}
</style>
