<template>
  <div class="form--switch-input" :class="additionalClasses">
    <div class="form--switch-input-wrapper"
         :class="{
            title: title,
            'title-top': titleTop
         }">
      <div v-if="title" class="form--switch-input--switch-title">
        {{ title }}
      </div>
      <div class="form--switch-input--switch-element">
        <div v-for="option in options" :key="option.label"
             class="form--switch-input--switch-element__value"
             @click="click(option)"
             :class="optionClasses(option)">
          {{ option.label }}
        </div>
      </div>
    </div>
    <span
        v-if="caption || error"
        class="form--input-caption"
        :class="error ? 'form--input-caption__error' : ''"
    >
			{{ error ? error : caption }}
		</span>
  </div>
</template>

<script>
export default {
  name: "SwitchInputForm",
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    /**
     * Значение поля
     * тип может быть любой
     * @type {any}
     */
    modelValue: {},
    /**
     * Аттрибут [disabled]
     * @type {boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Сообщение об ошибке
     * @type {string}
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * Аттрибут [readonly]
     * @type {boolean}
     */
    readonly: {
      type: Boolean,
      default: false,
    },
    /**
     * Выводить заголовок над
     * @type {boolean}
     */
    titleTop: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => ([
        {
          label: 'И',
          value: false,
        },
        {
          label: 'ИЛИ',
          value: true,
        },
      ])
    },
    title: {
      type: String,
      default: undefined,
    },
    /**
     * Подсказка
     * @type {string}
     */
    caption: {
      type: String,
      default: '',
    },
    /**
     * Дополнительные классы
     * vue/require-valid-default-prop
     * @type {Array}
     */
    classes: {
      type: Array,
      default: undefined
    },
    small: {
      type: Boolean,
      default: false
    },
  },
  emits: ['change'],
  data() {
    return {
      id: 'switch-input-' + Math.random(),
      modelProxy: null,
    }
  },
  created() {
    if (this.modelValue === undefined || this.modelValue === null) {
      this.modelProxy = this.options[0].value;
      this.change();
    } else {
      this.modelProxy = this.modelValue;
    }
  },
  computed: {
    optionClasses() {
      return (option) => ({
        active: this.modelProxy === option.value
      })
    },
    additionalClasses() {
      const res = [];

      if (Array.isArray(this.classes)) {
        this.classes.forEach(c => {
          res.push(c);
        })
      }

      if (this.error) {
        res.push('error');
      }

      if (this.small) {
        res.push('small');
      }

      if (this.disabled) {
        res.push('disabled');
      }

      return res;
    },
  },
  watch: {
    modelValue(modelValue) {
      this.modelProxy = modelValue;
    },
  },
  methods: {
    click(option) {
      if (this.disabled) {
        return;
      }

      this.modelProxy = option.value;
      this.change();
    },
    change() {
      this.$emit('change', this.modelProxy);
    },
  }
}
</script>
