<template>
  <modal-ui
    ref="modal"
    :visible="isModalOpen"
    :width="step.width || 'm'"
    :height="step.height || '510px'"
    min-height="510px"
    prevent-closure
    @close="cancel"
  >
    <template #body>
      <transition name="opacity-delayed" mode="out-in">
        <component v-if="step.noWrapper" :is="step.component" v-model="formData"></component>

        <document-master-wrapper v-else>
          <component ref="step" :is="step.component" :key="step.title" :step="step" v-model="formData"></component>
        </document-master-wrapper>
      </transition>

      <attention-modal :visible="isWarningOpen" @update:visible="setWarningOpen" @agree="close">
        <p>Вы собираетесь остановить процесс создания документа</p>
        <p>Данная операция не обратима!</p>
        <p>Продолжить?</p>
      </attention-modal>
    </template>
  </modal-ui>
</template>

<script>
import {defineComponent} from 'vue';
import ModalUi from '@/components/ui/ModalUi.vue';
import AttentionModal from '@/components/modals/AttentionModal.vue';
import DocumentMasterWrapper from '@/components/document-master/wrapper/DocumentMasterWrapper.vue';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import FORM_DATA from '@/common/consts/document-master/formValue';

export default defineComponent({
  name: 'CreateDocModal',
  components: {
    AttentionModal,
    DocumentMasterWrapper,
    ModalUi,
  },
  data() {
    return {
      formData: JSON.parse(JSON.stringify(FORM_DATA)), // TODO: Перенести в store
    };
  },
  computed: {
    ...mapState('master', ['isModalOpen', 'isWarningOpen', 'isLoading']),
    ...mapGetters('master', ['step']),
  },
  methods: {
    ...mapMutations('master', ['setWarningOpen']),
    ...mapActions('master', ['cancel', 'close']),
  },
  watch: {
    // TODO: Отказаться от использования общего лоадера
    isLoading(value) {
      if (value) {
        this.$refs.modal.showLoader();
      } else {
        this.$refs.modal.hideLoader();
      }
    },
    isModalOpen(value) {
      if (!value) {
        this.formData = JSON.parse(JSON.stringify(FORM_DATA)); // TODO: Перенести в store
      }
    },
  },
});
</script>
