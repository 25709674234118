<template>
  <div class="sign--table--row">
    <div class="sign--table--col--actions">
      <label v-if="file.base64" class="checkbox-container-custom" :for="checkboxId">
        <input type="checkbox" :id="checkboxId" v-model="file.active">
        <span class="checkmark"></span>
      </label>

      <div v-else class="spinner-alpha-container-sm ml-2">
        <i class="spinner-alpha spinner-alpha-sm"></i>
      </div>
    </div>

    <file-name-button class="sign--table--col--description" :file="documentFile"></file-name-button>

    <div class="sign--table--col--redaction">
      {{ file['Редакция'] }}
    </div>

    <div v-if="filesForSignHasRemarkGetter" class="sign--table--col--remark" :title="file['Примечание']">
      {{ file['Примечание'] }}
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import FileNameButton from '@/components/doc/files/FileNameButton.vue';
import DocumentFile from '@/common/models/file/document-file';

export default defineComponent({
  name: 'RowTableSign',
  components: {FileNameButton},
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['filesForSignHasRemarkGetter']),
    checkboxId() {
      return 'select-sign-file-' + this.file['F_ID'];
    },
    documentFile() {
      return new DocumentFile(this.file);
    },
  },
});
</script>
