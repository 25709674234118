<template>
  <div class="search-multiple-ui">
    <search-ui
      ref="search"
      :placeholder="placeholder"
      :get-options="getNewOptions"
      v-model="searchModel"
      @update:model-value="search"
    ></search-ui>

    <div class="wrapper" :class="{_scroll: scroll}">
      <card-list-ui
        v-if="modelValue.length"
        :list="modelValue"
        :card-footer="cardFooter"
        @change="change"
        @remove="remove"
      ></card-list-ui>

      <button-ui v-else color="black" mode="outline" @click="$refs.search.focus()">
        <template #prefix>
          <component :is="addIcon"></component>
        </template>
        <span>{{ addText }}</span>
      </button-ui>

      <loader-ui v-if="isLoading"></loader-ui>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import CardListUi from '@/components/ui/CardListUi.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import SearchUi from '@/components/ui/SearchUi.vue';
import abort from '@/mixins/abort';
import {CanceledError} from 'axios';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import {ErrorHelper} from '@/services/errorHelper';
import {keys} from '@/common/utils/props-validators';

export default defineComponent({
  name: 'SearchMultipleUi',
  mixins: [abort],
  components: {
    SearchUi,
    ButtonUi,
    CardListUi,
    LoaderUi,
  },
  model: {
    prop: 'modelValue',
    event: 'update:model-value',
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
      validator: keys('id', 'title', 'icon'),
    },
    placeholder: {
      type: String,
      default: 'Выберите значение',
    },
    addIcon: {
      type: Object,
      required: true,
    },
    addText: {
      type: String,
      default: 'Добавить',
    },
    getOptions: {
      type: Function,
      required: true,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
    cardFooter: Object,
    getCard: {
      type: Function,
      required: true,
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      searchModel: null,
      isLoading: false,
    };
  },
  methods: {
    async getNewOptions(...args) {
      const options = await this.getOptions(...args);
      return options.filter(option => this.modelValue.every(current => current.id !== option.code));
    },
    async search(option) {
      if (!option) {
        return;
      }

      this.$nextTick(() => this.searchModel = null);
      this.isLoading = true;

      try {
        const item = await this.getCard(option.code, this.abortController.signal);
        this.$emit('update:model-value', [...this.modelValue, item]);
        this.isLoading = false;
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }

        this.$notify({
          title: `Ошибка получения опции для поля "${this.placeholder}"`,
          type: ERROR_NOTIFY_TYPE,
          text: ErrorHelper.format(error),
        });
        this.isLoading = false;
      }
    },
    change({item, changes}) {
      const newValue = this.modelValue.map(current => current.id === item.id ? {...item, ...changes} : current);
      this.$emit('update:model-value', newValue);
    },
    remove(id) {
      const newValue = this.modelValue.filter(current => current.id !== id);
      this.$emit('update:model-value', newValue);
    },
  },
});
</script>

<style scoped lang="scss">
.search-multiple-ui {
  display: flex;
  flex-direction: column;
}

.search-ui {
  margin-bottom: 8px;
}

.wrapper {
  flex-grow: 1;

  position: relative;

  min-height: 120px;

  display: flex;
  justify-content: center;

  border: var(--border-dashed-gray-400);
  border-radius: 8px;

  &._scroll {
    overflow-y: auto;
  }
}

.button-ui {
  margin: auto;
  align-self: center;
}

.loader-ui {
  border-radius: 8px;
}
</style>
