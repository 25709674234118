<template>
  <modal-ui ref="modal" :visible="visible" title="Внимание" popup @update:visible="$emit('update:visible')">
    <template #body>
      <div class="content">
        <slot></slot>
      </div>
    </template>

    <template #footer>
      <close-button @click="hide"></close-button>

      <stop-doc-button @click="next">
        <template #title>Продолжить</template>
        <template #icon>
          <check-icon/>
        </template>
      </stop-doc-button>
    </template>
  </modal-ui>
</template>

<script>
import {defineComponent} from 'vue';
import CloseButton from '@/components/buttons/CloseButton';
import StopDocButton from '@/components/buttons/StopDocButton';
import CheckIcon from '@/components/icons/CheckIcon.vue';
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';

export default defineComponent({
  name: 'AttentionModal',
  mixins: [modal],
  components: {
    ModalUi,
    CheckIcon,
    StopDocButton,
    CloseButton,
  },
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: Boolean,
  },
  emits: ['update:visible', 'agree'],
  methods: {
    next() {
      this.hide();
      this.$emit('agree');
    },
  },
});
</script>

<!-- TODO: Отказаться от перекрытия стилей -->
<style scoped lang="scss">
.content :deep(p) {
  margin-bottom: 0 !important;
}
</style>
