<template>
  <div v-center class="error-legacy-ui">
    {{ text }}
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import Center from '@/directives/center';

/** @deprecated: Используйте ErrorUi */
export default defineComponent({
  name: 'ErrorLegacyUi',
  props: {
    text: String,
  },
  directives: {
    Center,
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors";

.error-legacy-ui {
  margin-bottom: 16px;
  padding: 8px 16px;

  overflow: hidden;
  text-overflow: ellipsis;
  color: $red-error-color;

  border: 1px solid $red-error-color;
  border-radius: 3px;
}
</style>
