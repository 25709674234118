<template>
  <div class="step-type">
    <form-builder ref="form" :fields="fields" v-model="model"></form-builder>

    <div v-if="showWarning" class="warning">
      <warn-icon></warn-icon>
      Данный проект не привязан по умолчанию для данного типа документа.
      <a href="#" @click.prevent="setDefaultProject">
        Исправить.
      </a>
    </div>

    <div class="background">
      <create-doc-icon></create-doc-icon>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import step from '@/mixins/document-master/step';
import FormBuilder from '@/components/form/FormBuilder.vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import dbAdapter from '@/mixins/dbAdapter';
import WarnIcon from '@/assets/svg/warn.svg?component';
import CreateDocIcon from '@/assets/svg/create-doc.svg?component';
import DocumentMasterFields from '@/common/consts/document-master/fields';

export default defineComponent({
  name: 'StepType',
  mixins: [step, dbAdapter],
  components: {
    FormBuilder,
    CreateDocIcon,
    WarnIcon,
  },
  computed: {
    ...mapState('master/type', ['type', 'project']),
    ...mapGetters('master/type', ['showWarning']),
    filterByType() {
      return options => {
        const projectIds = this.type.projectIds;
        return projectIds.length ? options.filter(option => projectIds.includes(option.code)) : options;
      };
    },
    fields() {
      return [
        DocumentMasterFields.TYPE,
        DocumentMasterFields.project(this.filterByType),
      ];
    },
    model: {
      get() {
        return {
          type: this.type,
          project: this.project,
        };
      },
      set(value) {
        this.setType(value.type);
        this.setProject(value.project);
      },
    },
  },
  methods: {
    ...mapActions('master/type', ['setType', 'setProject']),
    setDefaultProject() {
      this.setProjectCode(this.type.defaultProjectId);
    },
    setProjectCode(code) {
      this.$refs.form.$refs.project[0].choose(code);
    },
  },
  watch: {
    type(type) {
      this.setProjectCode(type.defaultProjectId);
    },
  },
});
</script>

<style scoped lang="scss">
.step-type {
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
}

.form-builder {
  margin-bottom: 16px;
  z-index: 2;
}

.warning {
  margin-bottom: -40px;
  z-index: 1;

  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
  color: var(--color-gray-600);
}

.background {
  flex-grow: 1;
  padding-top: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
