import HttpService from '@/services/api/http.service';
import DocumentFile from '@/common/models/file/document-file';
import {DocumentEndPoint} from '@/common/consts/endPoint';
import DocumentOption from '@/common/models/option/document-option';
import DocumentCard from '@/common/models/card/document-card';

export default class DocumentApiService {
  static async getDocument(id, signal) {
    const response = await HttpService.post(DocumentEndPoint.GetList, {
      where: `DOC_ID = ${id}`,
    }, signal);

    return new DocumentCard(response.items[0]);
  }

  static async getOptions(query, signal) {
    const response = await HttpService.post(DocumentEndPoint.GetOptions, {
      query,
    }, signal);

    return response.items.map(item => new DocumentOption(item));
  }

  static async getFiles(id, signal) {
    const response = await HttpService.post(DocumentEndPoint.GetFiles, {
      doc_id: id,
    }, signal);

    return response.items.map(item => new DocumentFile(item));
  }

  static async stop(id, comment, signal) {
    return await HttpService.post(DocumentEndPoint.Stop, {
      doc_id: id,
      comment,
    }, signal);
  }
}
