import {saveAs} from '@/services/fileSaver';

export const saveFile = (url, fileName) => {
  try {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.onload = () => saveAs(url, fileName);
    xhr.send();
  } catch {
    const link = document.createElement('a');
    link.setAttribute('download', fileName);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};
