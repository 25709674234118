import StepLaunch from '@/components/document-master/steps/StepLaunch.vue';
import {StepCode} from '@/common/consts/document-master/common';

export default {
  code: StepCode.Launch,
  title: 'Параметры запуска документа',
  tooltip: 'Карточка документа создана, не забудьте запустить его в работу',
  menuTitle: 'Параметры запуска',
  component: StepLaunch,
};
