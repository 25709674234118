<template>
  <section class="step-content">
    <div class="left">
      <document-master-header animation="opacity"></document-master-header>

      <div class="scroll">
        <loader-ui v-if="isLoading" color="white" position="static" text="Загрузка параметров"></loader-ui>

        <form-builder ref="form" :fields="fields" v-model="model"></form-builder>
      </div>
    </div>

    <div class="right">
      <files-upload :parent-table-id="USERS_TABLE" :parent-id="userId" v-model="filesModel"></files-upload>

      <document-master-footer></document-master-footer>
    </div>
  </section>
</template>

<script>
import {defineComponent} from 'vue';
import FormBuilder from '@/components/form/FormBuilder.vue';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import {COUNTERPARTIES_REGIONS_SEGMENTS__VIEW} from '@/configs/events';
import FilesUpload from '@/components/document-master/FilesUpload.vue';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import DocumentMasterApiService from '@/services/api/document-master/document-master-api.service';
import {CanceledError} from 'axios';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import {ErrorHelper} from '@/services/errorHelper';
import DocumentMasterHeader from '@/components/document-master/wrapper/DocumentMasterHeader.vue';
import DocumentMasterFooter from '@/components/document-master/wrapper/DocumentMasterFooter.vue';
import DocumentMasterFields from '@/common/consts/document-master/fields';
import abort from '@/mixins/abort';
import step from '@/mixins/document-master/step';
import {USERS_TABLE} from '@/configs/dbTables';

export default defineComponent({
  name: 'StepContent',
  mixins: [abort, step],
  components: {
    DocumentMasterFooter,
    DocumentMasterHeader,
    LoaderUi,
    FilesUpload,
    FormBuilder,
  },
  data() {
    return {
      USERS_TABLE,
    };
  },
  async mounted() {
    void this.getFields();
  },
  computed: {
    ...mapState('user', ['userId']),
    ...mapState('master/type', ['type']),
    ...mapState('master/content', ['content']),
    ...mapState('master/parameters', {parameters: 'parameters', parametersModel: 'model'}),
    ...mapState('master/links', ['links']),
    ...mapState('master/clients', ['clients']),
    ...mapState('master/comment', ['comment']),
    ...mapState('master/files', ['files']),
    ...mapGetters(['accessToEvent']),
    ...mapGetters('master/deadline', {deadline: 'get'}),
    parametersFields() {
      if (this.isLoading) {
        return [];
      }

      return this.parameters.length
        ? DocumentMasterFields.parameters(this.parameters, this.type.code)
        : [DocumentMasterFields.CONTENT];
    },
    fields() {
      return [
        ...this.parametersFields,
        DocumentMasterFields.DEADLINE,
        DocumentMasterFields.LINKS,
        this.accessToEvent(COUNTERPARTIES_REGIONS_SEGMENTS__VIEW) && DocumentMasterFields.CLIENTS,
        DocumentMasterFields.COMMENT,
      ].filter(field => !!field);
    },
    model: {
      get() {
        return {
          content: this.content,
          parameters: this.parametersModel,
          deadline: this.deadline,
          links: this.links,
          clients: this.clients,
          comment: this.comment,
        };
      },
      set({content, parameters, deadline, links, clients, comment}) {
        this.setContent(content);
        this.setParametersModel(parameters);
        this.setDeadline(deadline);
        this.setLinks(links);
        this.setClients(clients);
        this.setComment(comment);
      },
    },
    filesModel: {
      get() {
        return this.files;
      },
      set(files) {
        this.setFiles(files);
      },
    },
  },
  methods: {
    ...mapMutations('master/content', {setContent: 'set'}),
    ...mapMutations('master/parameters', {setParameters: 'setParameters', setParametersModel: 'setModel'}),
    ...mapMutations('master/deadline', {setDeadline: 'set'}),
    ...mapMutations('master/comment', {setComment: 'set'}),
    ...mapActions('master/links', {setLinks: 'set'}),
    ...mapActions('master/clients', {setClients: 'set'}),
    ...mapActions('master/files', {setFiles: 'set'}),
    async getFields() {
      if (!this.type.mask || this.parameters.length) {
        return;
      }

      this.isLoading = true;

      try {
        const parameters = await DocumentMasterApiService.getParameters(this.type.code, this.abortController.signal);
        this.setParameters(parameters);
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }

        this.$notify({
          title: 'Ошибка получения параметров типа документа',
          type: ERROR_NOTIFY_TYPE,
          text: ErrorHelper.format(error),
        });
        this.back();
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.step-content {
  height: 100%;
  display: flex;
}

.left {
  flex: 0 0 320px;
  min-width: 0;

  margin-right: 8px;
  padding-right: 16px;

  display: flex;
  flex-direction: column;
}

.document-master-header {
  margin-bottom: 20px;
}

.scroll {
  flex: 1 1 100%;
  overflow-y: auto;

  margin: -12px -16px -16px -16px;
  padding: 12px 16px 16px 16px;
}

.loader-ui {
  max-height: 250px;
}

.form-builder {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  // TODO: Отказаться от перекрытия стилей
  &:deep(.form__row) {
    &:nth-last-child(4),
    &:nth-last-child(5) {
      margin-bottom: 16px;
    }
  }
}

.right {
  flex: 1 1 100%;

  display: flex;
  flex-direction: column;
}

.files-upload {
  flex: 1 1 100%;
  margin-bottom: 16px;
}
</style>
