import DOCIcon from '@/assets/svg/files/doc.svg?component';
import XLSIcon from '@/assets/svg/files/xls.svg?component';
import JPGIcon from '@/assets/svg/files/jpg.svg?component';
import ODSIcon from '@/assets/svg/files/ods.svg?component';
import ODTIcon from '@/assets/svg/files/odt.svg?component';
import PDFIcon from '@/assets/svg/files/pdf.svg?component';
import PNGIcon from '@/assets/svg/files/png.svg?component';
import RTFIcon from '@/assets/svg/files/rtf.svg?component';
import UnknownIcon from '@/assets/svg/files/unknown.svg?component';

export class BaseFile {
  constructor(id, name, size) {
    this.id = id;
    this.name = name;
    this.size = size;
    this.title = this._getTitle(name);
    this.extension = this._getExtension(name);
    this.icon = this._getIcon(this.extension);
  }

  _getTitle(name) {
    return name.split('.').slice(0, -1).join('.');
  }

  _getExtension(name) {
    return name.split('.').pop().toLowerCase();
  }

  _getIcon(extension) {
    switch (extension) {
      case 'doc':
      case 'docx':
      case 'docm':
        return DOCIcon;
      case 'xls':
      case 'xlsm':
      case 'xlsx':
      case 'xltx':
      case 'xlsb':
      case 'xlam':
        return XLSIcon;
      case 'jpg':
      case 'jpeg':
        return JPGIcon;
      case 'ods':
        return ODSIcon;
      case 'odt':
        return ODTIcon;
      case 'pdf':
        return PDFIcon;
      case 'png':
        return PNGIcon;
      case 'rtf':
        return RTFIcon;
      default:
        return UnknownIcon;
    }
  }
}
