import HttpService from '@/services/api/http.service';
import {FILE_ATTRIBUTES, FILE_GET_META_DELAY, FILE_GET_META_MAX_ATTEMPTS} from '@/services/api/file/file-api.consts';
import {delay} from '@/common/utils/promise';
import {FileEndPoint} from '@/common/consts/endPoint';
import ApiFile from '@/common/models/file/api-file';
import {CanceledError} from 'axios';

export default class FileApiService {
  static async getUserFile(id, file, signal) {
    const response = await HttpService.post(FileEndPoint.GetUserFiles, {
      where: `f_id = ${id}`,
    }, signal);

    return new ApiFile(response.items[0], file);
  }

  static async upload(parentTableId, parentId, file, signal) {
    const {file_edition} = await HttpService.post(FileEndPoint.GetEdition, {
      parent_table_id: parentTableId,
      parent_id: parentId,
      name: file.name,
    }, signal);

    const {file_id} = await HttpService.post(FileEndPoint.Add, {
      parent_table_id: parentTableId,
      parent_id: parentId,
      file_name: file.name,
      file_size: file.size,
      file_content: file.base64,
      file_edition,
      comment: file.comment || undefined,
      attributes: FILE_ATTRIBUTES,
    }, signal);

    return file_id;
  }

  static async edit(file, pages, annotations) {
    const edits = Object.entries(annotations).map(([page, svg]) => ({
      page_url: pages[page - 1].url,
      page_edits_svg: svg,
    }));

    return await HttpService.post(FileEndPoint.AddEdits, {
      parent_table_id: file.parentTableId,
      parent_id: file.parentId,
      file_edits: edits,
      file_name: file.name,
      comment: file.comment,
      attributes: FILE_ATTRIBUTES,
    });
  }

  static async getPagesCount(id, hash, signal, attempt = 1) {
    try {
      const {count_pages} = await HttpService.post(FileEndPoint.GetMeta, {
        file_hash: hash,
      }, signal);

      return count_pages;
    } catch (error) {
      if (error instanceof CanceledError || error.error_code !== 1001 || attempt >= FILE_GET_META_MAX_ATTEMPTS) {
        throw error;
      }

      await delay(FILE_GET_META_DELAY);
      return await FileApiService.getPagesCount(id, hash, signal, attempt + 1);
    }
  }

  static async addView(id, signal) {
    return await HttpService.post(FileEndPoint.AddView, {
      file_ids: [id],
    }, signal);
  }

  static async move(fileId, documentId, signal) {
    return await HttpService.post(FileEndPoint.Move, {
      file_id: fileId,
      doc_id: documentId,
    }, signal);
  }
}
