import {defineComponent} from 'vue';
import {mapActions, mapMutations} from 'vuex';

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:model-value',
  },
  props: {
    /** @deprecated: Использовать store */
    modelValue: Object,
  },
  emits: ['update:model-value'],
  data() {
    return {
      /** @deprecated: Использовать store */
      formDataProxy: this.modelValue,
      isLoading: false,
    };
  },
  mounted() {
    this.setFunctions({validate: this.validate, ready: this.ready});
  },
  methods: {
    ...mapActions('master', ['back']),
    ...mapMutations('master', ['setLoading', 'setFunctions']),
    validate() {
      return !this.isLoading && (!this.$refs.form || this.$refs.form.validate());
    },
    ready() {
      throw new Error('Функция ready не определена');
    },
    /** @deprecated: Использовать store */
    change() {
      this.$emit('update:model-value', this.formDataProxy);
    },
    /** @deprecated: Не использовать общий loader */
    showLoader() {
      this.setLoading(true);
    },
    /** @deprecated: Не использовать общий loader */
    hideLoader() {
      this.setLoading(false);
    },
  },
  watch: {
    modelValue: {
      handler(value) {
        this.formDataProxy = value;
      },
      deep: true,
      immediate: true,
    },
  },
});
