import {delay} from '@/common/utils/promise';
import axios, {CanceledError} from 'axios';
import {ATTEMPTS_COUNT, RELOAD_DELAY} from '@/services/api/storage/storage-api.consts';

export default class StorageApiService {
  static async load(url, signal, _attempt = 1) {
    try {
      await axios.get(url, {signal});
    } catch (error) {
      if (error instanceof CanceledError || _attempt >= ATTEMPTS_COUNT) {
        throw error;
      }

      await delay(RELOAD_DELAY);
      await StorageApiService.load(url, signal, _attempt + 1);
    }
  }
}
