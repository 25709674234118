export default {
  namespaced: true,
  state: {
    comment: null,
  },
  mutations: {
    set(state, payload) {
      state.comment = payload || null;
    },
    clear(state) {
      state.comment = null;
    },
  },
};
