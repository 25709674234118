export default {
  namespaced: true,
  state: {
    contractors: [],
  },
  mutations: {
    set(state, payload) {
      state.contractors = payload;
    },
    clear(state) {
      state.contractors = [];
    },
  },
};
