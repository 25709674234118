<template>
  <transition name="opacity-fast">
    <div v-if="text" v-center class="error-ui">
      <cancel-icon class="alphadoc-icon"></cancel-icon>
      {{ text }}
    </div>
  </transition>
</template>

<script>
import {defineComponent} from 'vue';
import Center from '@/directives/center';
import CancelIcon from '@/assets/svg/cancel.svg?component';

export default defineComponent({
  name: 'ErrorUi',
  components: {
    CancelIcon,
  },
  props: {
    text: String,
  },
  directives: {
    Center,
  },
});
</script>

<style scoped lang="scss">
.error-ui {
  padding: 12px 16px;

  display: flex;
  align-items: center;

  background-color: var(--color-red-100);
  border-radius: 8px;

  color: var(--color-red-1000);
  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
}

.alphadoc-icon {
  flex-shrink: 0;
  margin-right: 8px;
}
</style>
