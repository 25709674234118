import HttpService from '@/services/api/http.service';
import {ClientEndPoint} from '@/common/consts/endPoint';
import ClientOption from '@/common/models/option/client-option';
import ClientCard from '@/common/models/card/client-card';

export default class ClientApiService {
  static async getClient(id, signal) {
    const response = await HttpService.post(ClientEndPoint.GetList, {
      where: `CLIENT_ID = ${id}`,
    }, signal);

    return new ClientCard(response.items[0]);
  }

  static async getOptions(query, signal) {
    const response = await HttpService.post(ClientEndPoint.GetOptions, {
      query,
    }, signal);

    return response.items.map(item => new ClientOption(item));
  }
}
