export const only = (...array) => value => array.includes(value);

const createUniversalValidator = fn => {
  const validator = (...array) => value => {
    if (typeof value !== 'object' || value === null) {
      return true;
    }

    if (Array.isArray(value)) {
      return value.every(validator(...array));
    }

    return fn(array, value);
  };

  return validator;
}

export const keys = createUniversalValidator((array, value) => {
  const objectKeys = Object.keys(value);
  return array.every(key => objectKeys.includes(key));
});

export const type = createUniversalValidator((array, value) => array.some(type => typeof value === type));

export const instance = createUniversalValidator((array, value) => array.some(type => value instanceof type));
