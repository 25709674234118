<template>
  <modal-ui ref="modal" title="Отменить / снять с контроля" @hidden="onHidden" @shown="onShown">
    <template #body>
      <div class="form-group m-0">
        <textarea-ui
          label="Вы собираетесь остановить (снять документ с контроля). Данная операция необратима! Продолжить?"
          v-model="comment"
          placeholder="Комментарий"
        ></textarea-ui>
      </div>

      <close-doc-orders-modal ref="confirmModal" @close="onConfirmModalClose" @hidden="onConfirmModalHidden"/>
    </template>

    <template #footer>
      <close-button @click="hide"/>
      <stop-doc-button @click="apply"/>
    </template>
  </modal-ui>
</template>

<script>
import {defineComponent} from 'vue';
import {notLike} from '@/services/utilsFunctions';
import {mapActions} from 'vuex';
import VuexAdapter from '@/services/vuexAdapter';
import {DOC_GANTT_ITEMS, DOC_ROUTE_POINT_MARK_CONFIRM} from '@/configs/endPoints';
import CloseButton from '@/components/buttons/CloseButton';
import StopDocButton from '@/components/buttons/StopDocButton';
import TextareaUi from '@/components/ui/TextareaUi.vue';
import ConstantsRPS from '@/configs/routePointStatuses';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';
import CloseDocOrdersModal from '@/components/modals/Doc/CloseDocOrdersModal.vue';
import {ErrorHelper} from '@/services/errorHelper';
import DocumentApiService from '@/services/api/document/document-api.service';

export default defineComponent({
  name: 'StopDocModal',
  components: {CloseDocOrdersModal, ModalUi, TextareaUi, StopDocButton, CloseButton},
  mixins: [modal],
  props: {
    docId: {
      type: Number,
      required: true,
    },
    events: {
      type: Array,
    },
  },
  data: () => ({
    isClosingConfirmed: null,
    comment: '',
    unconfirmedOrdersRpIds: [],
  }),
  methods: {
    ...mapActions({
      docRoutePointMarkConfirmAction: VuexAdapter.getNameAction(DOC_ROUTE_POINT_MARK_CONFIRM),
      docGanttItemsAction: VuexAdapter.getNameAction(DOC_GANTT_ITEMS),
    }),
    async onShown() {
      this.showLoader();

      setTimeout(async () => {
        const data = await this.docGanttItemsAction({doc_id: this.docId});

        if (data?.data?.count) {
          for (const item of data.data.items) {
            if (ConstantsRPS.rp_status_type_completed_id == item.RPS_ID && notLike(item.PARENTS, '##')) {
              this.unconfirmedOrdersRpIds.push(item.RP_ID);
            }
          }

          if (this.unconfirmedOrdersRpIds.length) {
            this.$refs.confirmModal.show();
          }
        }
        this.hideLoader();
      });
    },
    async apply() {
      this.showLoader();
      try {
        if (this.isClosingConfirmed && this.unconfirmedOrdersRpIds.length) {
          for (const rp_id of this.unconfirmedOrdersRpIds) {
            await this.docRoutePointMarkConfirmAction({
              rp_id,
              comment: 'Подтверждено при остановке документа',
            });
          }
        }

        await DocumentApiService.stop(this.docId, this.comment);

        this.throwEvents();
      } catch (error) {
        this.$notify({
          title: 'Ошибка остановки документа',
          type: ERROR_NOTIFY_TYPE,
          text: ErrorHelper.format(error),
        });
        this.hideLoader();
      }
    },
    throwEvents() {
      this.events.forEach((value) => {
        this.$eventBus.$emit(value);
      });
      this.hide();
    },
    onHidden() {
      this.isClosingConfirmed = null;
      this.comment = '';
      this.unconfirmedOrdersRpIds = [];
    },
    onConfirmModalClose(isConfirmed) {
      this.isClosingConfirmed = isConfirmed;
      this.$refs.confirmModal.hide();
    },
    onConfirmModalHidden() {
      if (typeof this.isClosingConfirmed !== 'boolean') {
        this.hide();
      }
    },
  },
});
</script>
