import StepResolution from '@/components/document-master/steps/StepResolution.vue';
import {StepCode} from '@/common/consts/document-master/common';

export default {
  code: StepCode.Resolutions,
  title: 'Добавление резолюции по документу',
  tooltip: 'Используйте резолюцию для добавления важной информации по документу',
  menuTitle: 'Резолюция',
  component: StepResolution,
};
