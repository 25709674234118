<template>
  <div class="performance-step-master">
    <div class="performance-step-master__items">
      <div v-for="performance in performanceShowed" :key="performance.model" class="performance-step-master__item">
        <div class="performance-step-master__item--icon">
          <loading-status-master-icon v-if="isStatus(performance.model, LOADING_MASTER_STEP_STATUS)"/>
          <stop-status-master-icon v-if="isStatus(performance.model, STOP_MASTER_STEP_STATUS)"/>
          <ready-status-master-icon v-if="isStatus(performance.model, READY_MASTER_STEP_STATUS)"/>
          <error-status-master-icon v-if="isStatus(performance.model, ERROR_MASTER_STEP_STATUS)"/>
        </div>

        <div class="performance-step-master__item--label">
          {{ performance.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import step from '@/mixins/document-master/step';
import {
  ERROR_MASTER_STEP_STATUS,
  LOADING_MASTER_STEP_STATUS,
  READY_MASTER_STEP_STATUS,
  STOP_MASTER_STEP_STATUS,
} from '@/configs/master/masterStepLoadingStatus';
import LoadingStatusMasterIcon from '@/components/icons/master/statuses/LoadingStatusMasterIcon.vue';
import StopStatusMasterIcon from '@/components/icons/master/statuses/StopStatusMasterIcon.vue';
import ReadyStatusMasterIcon from '@/components/icons/master/statuses/ReadyStatusMasterIcon.vue';
import ErrorStatusMasterIcon from '@/components/icons/master/statuses/ErrorStatusMasterIcon.vue';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import VuexAdapter from '@/services/vuexAdapter';
import {DOC_ADD, DOC_LINK_ADD, DOC_ROUTE_ADD, SYSTEM_COMMENT_ADD} from '@/configs/endPoints';
import {COPY_ROUTE, NONE_ROUTE, STANDARD_ROUTE} from '@/configs/routeTypes';
import {CLIENTS_TABLE, DOCS_TABLE} from '@/configs/dbTables';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import {CALENDAR_DAYS_TERM_FROM_MASTER, DATE_TERM_FROM_MASTER} from '@/configs/master/termFromMaster';
import ExternalFile from '@/common/models/file/external-file';
import FileApiService from '@/services/api/file/file-api.service';
import DocumentApiService from '@/services/api/document/document-api.service';

export default defineComponent({
  name: 'StepExecution',
  mixins: [step],
  components: {ErrorStatusMasterIcon, ReadyStatusMasterIcon, StopStatusMasterIcon, LoadingStatusMasterIcon},
  data() {
    return {
      ERROR_MASTER_STEP_STATUS,
      READY_MASTER_STEP_STATUS,
      STOP_MASTER_STEP_STATUS,
      LOADING_MASTER_STEP_STATUS,
      errors: [],
      stop: false,
      performance: [
        {
          label: 'Сохранение регистрационной карточки документа',
          model: 'doc',
          required: true,
          handler: async () => {
            const res = await this.addDoc({
              dt_id: this.type.code,
              p_id: this.project.code,
              controller_id: this.controllerId,
              content: this.content,
              comment: this.comment,
              mask_params: this.parametersRequest,
            });

            this.$set(this.formDataProxy, 'doc_id', res.data.doc_id);
            this.change();
          },
        },
        {
          label: 'Организация связей',
          model: 'links',
          handler: async () => {
            for (const link of this.links) {
              await this.addDocLink({
                doc_id: this.formDataProxy.doc_id,
                parent_table_id: DOCS_TABLE,
                parent_id: link.id,
                lt_id: link.linkType.code,
              });
            }
            for (const client of this.clients) {
              await this.addDocLink({
                doc_id: this.formDataProxy.doc_id,
                parent_table_id: CLIENTS_TABLE,
                parent_id: client.id,
                lt_id: client.linkType.code,
              });
            }
          },
          hide: () => (!this.steps.links || !this.links.length) && (!this.steps.clients || !this.clients.length),
        },
        {
          label: 'Создание маршрута для документа',
          model: 'temps',
          handler: async () => {
            const routePointsResponsibles = [];

            this.formDataProxy.routes_responsible_unspecified_steps.forEach((_, number) => {
              const days = this.formDataProxy['routes_responsible_description_term_' + number] === CALENDAR_DAYS_TERM_FROM_MASTER
                ? this.formDataProxy['routes_responsible_description_term_days' + number] : null;

              const end_date = this.formDataProxy['routes_responsible_description_term_' + number] === DATE_TERM_FROM_MASTER
                ? this.formDataProxy['routes_responsible_description_term_date' + number] : null;

              routePointsResponsibles.push({
                RESPONSIBLE_TABLE_ID: this.formDataProxy['routes_responsible_' + number].data.RESPONSIBLE_TABLE_ID,
                RESPONSIBLE_ID: this.formDataProxy['routes_responsible_' + number].data.RESPONSIBLE_ID,
                description: this.formDataProxy['routes_responsible_description_' + number],
                comment: this.formDataProxy['routes_responsible_description_' + number],
                days,
                end_date,
              });
            });

            await this.addStandardRoute({
              DOC_ID: this.formDataProxy.doc_id,
              PARENT_DOC_ID: this.formDataProxy.route_doc_id.code,
              routePointsResponsibles,
            });
          },
          hide: () => this.formDataProxy.type_route !== STANDARD_ROUTE,
        },
        {
          label: 'Копирование маршрута из документа',
          model: 'copy_route',
          handler: async () => {
            await this.addStandardRoute({
              DOC_ID: this.formDataProxy.doc_id,
              PARENT_DOC_ID: this.formDataProxy.route_copy_doc_id.code,
            });
          },
          hide: () => this.formDataProxy.type_route !== COPY_ROUTE,
        },
        {
          label: 'Добавление резолюций',
          model: 'resolution',
          handler: async () => {
            await this.addCommentsAction({
              parent_table_id: DOCS_TABLE,
              parent_id: this.formDataProxy.doc_id,
              comment: this.formDataProxy.resolution,
            });
          },
          hide: () => !this.formDataProxy.resolution?.length,
        },
        {
          label: 'Добавление файлов',
          model: 'files',
          handler: async () => {
            await Promise.all(
              this.files.map(async file => {
                if (file instanceof ExternalFile) {
                  return await FileApiService.upload(DOCS_TABLE, this.formDataProxy.doc_id, file);
                } else {
                  return await FileApiService.move(file.apiId, this.formDataProxy.doc_id);
                }
              }),
            );
          },
          hide: () => !this.steps.files || !this.files.length,
        },
        {
          label: 'Остановка документа',
          model: 'routes',
          handler: async () => await DocumentApiService.stop(
            this.formDataProxy.doc_id,
            'Остановлено автоматически при создании документа',
          ),
          hide: () => this.formDataProxy.type_route !== NONE_ROUTE,
        },
      ],
    };
  },
  async created() {
    if (!this.formDataProxy.created) {
      this.setCreated();

      for (const step of this.performanceShowed) {
        this.setStepStatus(step.model, LOADING_MASTER_STEP_STATUS);
      }
      this.setControlsDisabled(true);

      await this.load();

      this.setControlsDisabled(false);

      if (!this.stop) {
        this.setCreatedDoc();
      }
    }
  },
  computed: {
    ...mapState('master/type', ['type', 'project']),
    ...mapState('master/content', ['content']),
    ...mapState('master/files', ['files']),
    ...mapState('master/steps', ['steps']),
    ...mapState('master/links', ['links']),
    ...mapState('master/clients', ['clients']),
    ...mapState('master/comment', ['comment']),
    ...mapGetters('master/controller', ['controllerId']),
    ...mapGetters('master/parameters', {parametersRequest: 'request'}),
    performanceShowed() {
      return this.performance.filter(p => !p?.hide || !p.hide());
    },
    isStatus() {
      return (model, status) => {
        return this.formDataProxy.loadingStatuses[model] === status;
      };
    },
  },
  methods: {
    ...mapMutations('master', ['setControlsDisabled', 'setCreated']),
    ...mapActions({
      addDoc: VuexAdapter.getNameAction(DOC_ADD),
      addStandardRoute: VuexAdapter.getNameAction(DOC_ROUTE_ADD),
      addCommentsAction: VuexAdapter.getNameAction(SYSTEM_COMMENT_ADD),
      addDocLink: VuexAdapter.getNameAction(DOC_LINK_ADD),
    }),
    async load() {

      this.errors = [];

      for (const step of this.performanceShowed) {
        if (this.stop) {
          this.setStepStatus(step.model, STOP_MASTER_STEP_STATUS);

          continue;
        }
        try {
          await step.handler();

          this.setStepStatus(step.model, READY_MASTER_STEP_STATUS);

        } catch (e) {
          console.error(e);
          this.errors.push(e.error_message || 'Ошибка в шаге: ' + step.label);

          this.setStepStatus(step.model, ERROR_MASTER_STEP_STATUS);

          if (step?.required) {
            this.stop = true;
          }
        }
      }

      if (this.errors.length) {
        this.$notify({
          title: 'Ошибки при создании документа',
          type: ERROR_NOTIFY_TYPE,
          text: this.errors.join('<br>'),
        });
      }
    },
    setStepStatus(model, status) {
      this.$set(this.formDataProxy.loadingStatuses, model, status);
      this.change();
    },
    setCreatedDoc() {
      this.$set(this.formDataProxy, 'created', true);
      this.change();
    },
  },
});
</script>
