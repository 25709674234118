import LinkCard from '@/common/models/card/link-card';

export default {
  namespaced: true,
  state: {
    links: [],
  },
  actions: {
    set({commit, dispatch}, payload) {
      if (payload.length > 0) {
        dispatch('master/steps/enable', 'links', {root: true});
      }
      commit('set', payload);
    },
  },
  mutations: {
    set(state, payload) {
      state.links = payload;
    },
    clearLinkTypes(state) {
      state.links = state.links.map(LinkCard.resetLinkType);
    },
    clear(state) {
      state.links = [];
    },
  },
};
