<template>
  <a
    class="file-truncated-link"
    :class="{_muted: isMuted, _disabled: isFileDownloading}"
    href="#"
    @click.prevent="downloadFile"
  >
    <file-truncated-name :file="file"></file-truncated-name>
  </a>
</template>

<script>
import downloadFile from '@/mixins/downloadFile';
import {defineComponent} from 'vue';
import DocumentFile from '@/common/models/file/document-file';
import FileTruncatedName from '@/components/doc/files/FileTruncatedName.vue';

export default defineComponent({
  name: 'FileTruncatedLink',
  mixins: [downloadFile],
  components: {
    FileTruncatedName,
  },
  props: {
    file: {
      type: DocumentFile,
      required: true,
    },
    isMuted: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
.file-truncated-link {
  min-width: 0;

  &._disabled {
    pointer-events: none;

    &:not(._muted) {
      color: var(--color-gray-400);
    }
  }

  &._muted {
    color: var(--color-gray-600);
    font-size: 10px;
    line-height: 14px;
  }
}
</style>
