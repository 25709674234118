<template>
  <div v-if="showView" class="file-view">
    <loader-ui v-if="state === State.Loading" position="static" color="white" text="Загрузка данных"></loader-ui>
    <file-error v-else-if="state === State.Error" @retry="load"></file-error>
    <iframe v-else-if="state === State.Defined" class="iframe" :src="url" width="100%" height="100%"></iframe>
  </div>

  <file-error v-else class="file-view" text="Предпросмотр для данного типа файла недоступен" no-retry></file-error>
</template>

<script>
import {defineComponent} from 'vue';
import FileError from '@/components/doc/files/FileError.vue';
import FileApiService from '@/services/api/file/file-api.service';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import abort from '@/mixins/abort';
import {State} from '@/common/consts/state.consts';
import ApiFile from '@/common/models/file/api-file';
import StorageApiService from '@/services/api/storage/storage-api.service';
import ExternalFile from '@/common/models/file/external-file';

export default defineComponent({
  name: 'FileView',
  mixins: [abort],
  components: {
    LoaderUi,
    FileError,
  },
  model: {
    prop: 'modelValue',
    event: 'update:model-value',
  },
  props: {
    modelValue: {
      type: [ExternalFile, ApiFile],
      required: true,
    },
    parentTableId: {
      type: Number,
      required: true,
    },
    parentId: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      state: State.Undefined,
      State,
    };
  },
  mounted() {
    this.load();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    // TODO: ALPHAWEB-1442
    showView() {
      return ['pdf', 'doc', 'docx', 'docm'].includes(this.modelValue.extension);
    },
    url() {
      if (this.modelValue instanceof ExternalFile) {
        throw new Error('Файл не был загружен. URL для получения PDF не определён');
      }

      return `${this.modelValue.pdfUrl}#toolbar=0&scrollbar=1`;
    },
  },
  methods: {
    load() {
      if (!this.showView) {
        return;
      }

      if (this.modelValue instanceof ApiFile) {
        this.loadView(this.modelValue);
      } else {
        this.upload();
      }
    },
    async loadView(file) {
      this.state = State.Loading;

      try {
        await FileApiService.addView(file.apiId, this.abortController.signal);
        await StorageApiService.load(file.pdfUrl, this.abortController.signal);
        this.state = State.Defined;
      } catch (error) {
        this.state = State.Error;
      }
    },
    async upload() {
      this.state = State.Loading;

      try {
        const id = await FileApiService.upload(
          this.parentTableId,
          this.parentId,
          this.modelValue,
          this.abortController.signal,
        );

        const file = await FileApiService.getUserFile(id, this.modelValue, this.abortController.signal);

        this.$emit('update:model-value', file);
        void this.loadView(file);
      } catch (error) {
        this.state = State.Error;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.iframe {
  border: none;
}
</style>
