<template>
  <ul class="card-list-ui" :class="{_field: withAdd}" @click.self="$emit('add')">
    <li v-for="(item, index) in list" :key="item.id" class="item">
      <card-ui
        :item="item"
        :icon="item.icon"
        :footer-component="cardFooter"
        @change="$emit('change', {item, changes: $event})"
        @remove="$emit('remove', item.id)"
      ></card-ui>

      <button-icon-ui v-if="withAdd && index === list.length - 1" class="plus" @click="$emit('add')">
        <plus-fill-icon></plus-fill-icon>
      </button-icon-ui>
    </li>
  </ul>
</template>

<script>
import {defineComponent} from 'vue';
import CardUi from '@/components/ui/CardUi.vue';
import {keys} from '@/common/utils/props-validators';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import PlusFillIcon from '@/assets/svg/plus-fill.svg?component';

export default defineComponent({
  name: 'CardListUi',
  components: {
    PlusFillIcon,
    ButtonIconUi,
    CardUi,
  },
  props: {
    list: {
      type: Array,
      required: true,
      validator: keys('id', 'title', 'icon'),
    },
    withAdd: {
      type: Boolean,
      default: false,
    },
    cardFooter: Object,
  },
  emits: ['change', 'remove', 'add'],
});
</script>

<style scoped lang="scss">
.card-list-ui {
  flex-grow: 1;
  padding: 8px;

  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  grid-auto-rows: min-content;

  &._field {
    padding-right: 40px;
  }
}

.item {
  position: relative;
}

.plus {
  position: absolute;
  top: 50%;
  right: -32px;

  transform: translateY(-50%);
}

.card-ui {
  height: 100%;
}
</style>
