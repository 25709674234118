<template>
  <combobox-ui
    color="dark-gray"
    no-clear
    :options="getLinkTypes"
    :model-value="linkType"
    @load="onLoad"
    @update:model-value="updateLinkType"
  ></combobox-ui>
</template>

<script>
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import DocumentMasterApiService from '@/services/api/document-master/document-master-api.service';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';
import {keys} from '@/common/utils/props-validators';

export default defineComponent({
  name: 'CardLinkTypes',
  components: {
    ComboboxUi,
  },
  props: {
    linkType: {
      type: Object,
      required: true,
      validator: keys('label', 'code'),
    },
    linkTypeTableId: {
      type: Number,
      required: true,
    },
    isLinkTypeInitial: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['change'],
  computed: {
    ...mapGetters('master/type', ['linkTypesIds']),
  },
  methods: {
    async getLinkTypes(signal) {
      return await DocumentMasterApiService.getLinkTypes(this.linkTypesIds, this.linkTypeTableId, signal);
    },
    onLoad(options) {
      if (this.isLinkTypeInitial) {
        this.updateLinkType(options[options.length - 1]);
      }
    },
    updateLinkType(linkType) {
      this.$emit('change', {linkType, isLinkTypeInitial: false});
    },
  },
});
</script>
