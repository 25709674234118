<template>
  <search-multiple-ui v-bind="field" v-model="model" scroll></search-multiple-ui>
</template>

<script>
import {defineComponent} from 'vue';
import step from '@/mixins/document-master/step';
import DocumentMasterFields from '@/common/consts/document-master/fields';
import SearchMultipleUi from '@/components/ui/SearchMultipleUi.vue';
import {mapActions, mapState} from 'vuex';

export default defineComponent({
  name: 'StepClients',
  mixins: [step],
  components: {
    SearchMultipleUi,
  },
  data() {
    return {
      field: DocumentMasterFields.CLIENTS,
    };
  },
  computed: {
    ...mapState('master/clients', ['clients']),
    model: {
      get() {
        return this.clients;
      },
      set(value) {
        this.set(value);
      },
    },
  },
  methods: {
    ...mapActions('master/clients', ['set']),
  },
});
</script>

<style scoped lang="scss">
.search-multiple-ui {
  height: 100%;
}
</style>
