<template>
  <thead class="table-active-filter-small" :class="{_active: searchShow}">
  <tr>
    <td class="cell">
      <input-ui
        class="item item_search"
        color="white"
        size="l"
        placeholder="Поиск"
        inputmode="search"
        v-model.trim="searchValue"
        no-clear
        @focus="activateSearch"
      >
        <template #postfix>
          <button-icon-ui @click.stop="applyFilter">
            <font-awesome-icon class="icon" :icon="['fas', 'search']"/>
          </button-icon-ui>
        </template>
      </input-ui>

      <button class="item item_clear" v-if="showClearFilterBtn" @click="clearFilters">
        <font-awesome-icon class="icon" :icon="['fa', 'xmark']"/>
      </button>

      <combobox-ui
        class="item item_sort"
        :class="{_hidden: sortHidden}"
        placeholder="Сортировка"
        size="l"
        color="white"
        :options="optionsSort"
        v-model="sortField"
      >
        <template #prefix>
          <button-icon-ui @click.stop="sort">
            <font-awesome-icon
              class="icon"
              :icon="['fas', sortable === TABLE_SORT_ASC ? 'sort-amount-desc' : 'sort-amount-asc']"
            />
          </button-icon-ui>
        </template>
      </combobox-ui>
    </td>
  </tr>

  <table-active-filter-small-search
    v-show="searchShow"
    :search-show="searchShow"
    @close="deactivateSearch"
    @clear="searchValue = ''"
    :search-value="searchValue"
    :configs_table="configs_table"
  />

  <portal to="modal">
    <transition name="opacity">
      <div v-if="searchShow" class="backdrop" @click="deactivateSearch"/>
    </transition>
  </portal>
  </thead>
</template>

<script>
import {useEventListener} from '@vueuse/core';
import TableActiveFilterSmallSearch from '@/components/table/activeFilters/TableActiveFilterSmallSearch';
import VuexAdapter from '@/services/vuexAdapter';
import {TABLE_SORT_ASC, TABLE_SORT_DESC} from '@/configs/tables/table';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';
import InputUi from '@/components/ui/InputUi.vue';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';

export default {
  name: 'TableActiveFilterSmall',
  inject: [
    'app',
    'layout',
  ],
  components: {
    ButtonIconUi,
    InputUi,
    ComboboxUi,
    TableActiveFilterSmallSearch,
  },
  props: ['configs_table'],
  data: () => ({
    searchKey: 'search',
    searchValue: '',
    keyFilter: 'LIKE',
    sortable: TABLE_SORT_ASC,
    sortField: null,
    searchShow: false,
    sortableShow: true,
    sortableInit: false,
    TABLE_SORT_ASC,
  }),
  created() {
    for (let key in this.tableFilterData) {

      if (this.tableFilterData[key][0] === undefined) {
        continue;
      }

      let data = this.tableFilterData[key][0];

      if (key == this.searchKey) {
        this.searchValue = data.search_value;
        break;
      }
    }

    if (this.tableOrderBy.key !== undefined && this.tableOrderBy.value !== undefined) {
      this.sortable = this.tableOrderBy.value;
      this.optionsSort.forEach(option => {
        if (this.tableOrderBy.key === option.code) {
          this.sortField = option;
        }
      });
    }
  },
  mounted() {
    this.$eventBus.$on('toolbar-click', this.deactivateSearch);

    setTimeout(() => this.sortableInit = true, 500);
    useEventListener(this.layout?.frameLeft, 'scroll', (event) => {
      this.scrollAnimation(event);
    });
  },
  beforeDestroy() {
    this.$eventBus.$off('toolbar-click', this.deactivateSearch);
  },
  watch: {
    sortField() {
      if (this.sortableInit) {
        this.applySort();
      }
    },
  },
  methods: {
    tableDelOrderBy() {
      this.$store.dispatch(VuexAdapter.delOrderByTableNameAction(this.configs_table.name));
    },
    tableDeleteALLFiltersAndOrderBy() {
      this.$store.dispatch(VuexAdapter.delFilterAndOrderByTableNameAction(this.configs_table.name));
    },
    tableSetOrderBy(data) {
      this.$store.dispatch(VuexAdapter.orderByTableNameAction(this.configs_table.name), data);
    },
    tableSortableStatus(data) {
      this.$store.commit(VuexAdapter.sortableStatusTableNameMutation(this.configs_table.name), data);
    },
    scrollAnimation(event) {
      let scrollOffset = event.target.scrollTop;
      let width = this.app.appWidth;

      if (scrollOffset >= 170 && width <= 440) {
        this.sortableShow = false;
        this.tableSortableStatus(false);

      } else if (scrollOffset < 110 && width <= 440) {
        this.sortableShow = true;
        this.tableSortableStatus(true);
      }
    },
    clearFilters() {
      this.searchValue = '';
      this.tableDeleteALLFiltersAndOrderBy();
    },
    sort() {
      this.sortable = this.sortable === TABLE_SORT_ASC ? TABLE_SORT_DESC : TABLE_SORT_ASC;
      if (this.sortField !== null) {
        this.applySort();
      }
    },
    applySort() {
      if (this.sortField !== null) {
        this.tableSetOrderBy({
          key: this.sortField.code,
          value: this.sortable,
        });
        return;
      }

      this.tableDelOrderBy();
    },
    applyFilter() {
      if (this.searchShow) {
        this.$emit('applyFilter');
      } else {
        this.activateSearch();
      }
    },
    activateSearch() {
      this.searchShow = true;
      this.$eventBus.$emit('search-active-change', true);
    },
    deactivateSearch() {
      this.searchShow = false;
      this.$eventBus.$emit('search-active-change', false);
    },
  },
  computed: {
    tableFilterData() {
      return this.$store.getters[VuexAdapter.filterTableNameGetter(this.configs_table.name)];
    },
    tableOrderBy() {
      return this.$store.getters[VuexAdapter.orderByTableNameGetter(this.configs_table.name)];
    },
    hasFilters() {
      return Object.keys(this.tableFilterData).length > 0;
    },
    showClearFilterBtn() {
      return !this.sortableShow && this.hasFilters;
    },
    optionsSort() {
      return Object.entries(this.configs_table.info)
        .filter((entry) => entry[1].visible && !!entry[1].title)
        .map(([key, value]) => ({label: value.title, code: key}));
    },
    sortHidden() {
      return !(this.sortableShow || this.searchShow);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/resolutions";

.table-active-filter-small {
  position: sticky;
  z-index: 11;
  top: 0;
  width: 100%;

  &._active {
    z-index: 1001;

    .item {
      &_search {
        position: absolute;
        left: 0;
        right: 0;
      }
    }
  }
}

.cell {
  position: relative;

  margin-bottom: 6px;
  margin-left: -12px;
  padding-top: 6px;

  display: flex;
  flex-wrap: wrap;

  &::before {
    content: "";
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    right: -8px;
    bottom: -8px;

    backdrop-filter: blur(6px);
    mask: linear-gradient(to bottom, rgba(0, 0, 0, 1) 75%, rgba(0, 0, 0, 0) 100%);

    @media #{$smaller-and-less} {
      right: 0;
    }
  }
}

.item {
  margin-bottom: 6px;
  margin-left: 12px;

  &_search {
    position: relative;
    z-index: 2;

    flex-grow: 2;
    min-width: 227px;
  }

  &_clear {
    position: relative;
    z-index: 1;

    flex-shrink: 0;
    width: 48px;
    height: 48px;

    background-color: var(--color-white);
    border-radius: 8px;
    box-shadow: var(--shadow);
  }

  &_sort {
    flex-grow: 1;
    min-width: 200px;

    margin-bottom: 6px;

    transition: opacity var(--transition), margin-top var(--transition);

    &._hidden {
      opacity: 0;
      margin-top: -56px;
    }
  }
}

.icon {
  width: 16px;
  height: 16px;

  :deep(path) {
    fill: var(--color-gray-500);
    transition: fill var(--transition-fast);
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  background-color: var(--color-backdrop);
  backdrop-filter: blur(5px);
}
</style>
