import {DeadlineType} from '@/common/consts/deadline';

export default {
  namespaced: true,
  state: {
    type: DeadlineType.None,
    workingDays: 1,
    workingDaysDate: null,
    days: 1,
    daysDate: null,
    date: null,
  },
  getters: {
    get(state) {
      return state;
    },
    deadline(state) {
      switch (state.type) {
        case DeadlineType.WorkingDays:
          return state.workingDaysDate;
        case DeadlineType.Days:
          return state.daysDate;
        case DeadlineType.Date:
          return state.date;
        default:
          return null;
      }
    },
    hasDeadline(state) {
      return state.type !== DeadlineType.None;
    },
  },
  mutations: {
    set(state, payload) {
      state.type = payload.type;
      state.workingDays = payload.workingDays;
      state.workingDaysDate = payload.workingDaysDate;
      state.days = payload.days;
      state.daysDate = payload.daysDate;
      state.date = payload.date;
    },
    clear(state) {
      state.type = DeadlineType.None;
      state.workingDays = 1;
      state.workingDaysDate = null;
      state.days = 1;
      state.daysDate = null;
      state.date = null;
    },
  },
};
