<template>
  <div class="dot-ui" :class="`_color-${color}`"></div>
</template>

<script>
import {defineComponent} from 'vue';
import {only} from '@/common/utils/props-validators';

export default defineComponent({
  name: 'DotUi',
  props: {
    color: {
      type: String,
      required: true,
      validator: only('green', 'purple', 'blue'),
    },
  },
});
</script>

<style scoped lang="scss">
.dot-ui {
  width: 12px;
  height: 12px;
  border-radius: 50%;

  &._color-green {
    background-color: var(--color-green-900);
  }

  &._color-purple {
    background-color: var(--color-purple-600);
  }

  &._color-blue {
    background-color: var(--color-blue-400);
  }
}
</style>
