<template>
  <div class="tabs-ui" :class="{'_full-height': fullHeight}">
    <header ref="resize" class="header">
      <div v-for="(tab, index) in tabs" :key="tab.id" class="wrapper">
        <button
          :ref="setTabRef"
          class="button"
          :class="{_active: tab.id === activeId}"
          :disabled="tab.id === activeId"
          @click="activeId = tab.id"
        >
          <slot name="title" :active="tab.id === activeId" v-bind="tab">
            <span class="title">{{ tab.title }}</span>
          </slot>
        </button>

        <button-icon-ui v-if="withAdd && index === tabs.length - 1" @click="$emit('add')">
          <plus-fill-icon></plus-fill-icon>
        </button-icon-ui>
      </div>
    </header>

    <div ref="content" class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent} from 'vue';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import PlusFillIcon from '@/assets/svg/plus-fill.svg?component';
import resize from '@/mixins/resize';

export default defineComponent({
  name: 'TabsUi',
  mixins: [resize],
  components: {
    ButtonIconUi,
    PlusFillIcon,
  },
  props: {
    withAdd: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add'],
  provide() {
    return {
      addTab: tab => {
        this.tabs = [...this.tabs, tab];

        if (this.tabs.length === 1) {
          this.activeId = tab.id;
        }
      },
      removeTab: id => {
        this.tabs = this.tabs.filter(tab => tab.id === id);
      },
      activeId: computed(() => this.activeId),
    };
  },
  data() {
    return {
      activeId: null,
      buttons: [],
      tabs: [],
      contentHeight: null,
      headerHeight: 40,
    };
  },
  mounted() {
    window.addEventListener('resize', this.calcContentHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calcContentHeight);
  },
  computed: {
    contentHeightStyle() {
      return this.contentHeight && `${this.contentHeight}px`;
    },
    activeTabIndex() {
      return this.tabs.findIndex(tab => tab.id === this.activeId);
    },
    /* Используется в стилях */
    pad() {
      const button = this.buttons[this.activeTabIndex];

      if (!button) {
        return 0;
      }

      const headerRect = this.$refs.resize.getBoundingClientRect();
      const buttonRect = button.getBoundingClientRect();

      return {
        top: `${buttonRect.top + buttonRect.height - headerRect.top}px`,
        left: `${buttonRect.left + buttonRect.width / 2 - headerRect.left}px`,
        width: `${button.offsetWidth - 16}px`,
        transition: this.headerHeight <= 40 ? 'left var(--transition-fast), width var(--transition-fast)' : null,
      };
    },
  },
  methods: {
    setActive(index) {
      const tab = this.tabs[index];

      if (tab) {
        this.activeId = tab.id;
      }
    },
    setTabRef(tab) {
      this.buttons = [...this.buttons, tab];
    },
    calcContentHeight() {
      if (this.fullHeight) {
        this.contentHeight = null;
        return;
      }

      const array = Array.from(this.$refs.content.children);
      const child = array.find(child => child.classList.contains('opacity-replaced-enter')) || array[0];
      this.contentHeight = child?.getBoundingClientRect().height || null;
    },
    onResize(dimensions) {
      this.headerHeight = dimensions.height;
    },
  },
  watch: {
    activeId() {
      this.$nextTick(() => this.calcContentHeight());
    },
    headerHeight() {
      this.$nextTick(() => this.calcContentHeight());
    },
  },
});
</script>

<style scoped lang="scss">
.tabs-ui {
  position: relative;

  display: flex;
  flex-direction: column;

  &._full-height {
    height: 100%;

    .tab-ui {
      height: 100%
    }
  }
}

.header {
  flex-shrink: 0;
  position: relative;
  margin: 0 -16px 8px -8px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &::after {
    content: '';
    display: block;

    position: absolute;
    top: v-bind('pad.top');
    left: v-bind('pad.left');
    width: v-bind('pad.width');
    height: 1px;
    transform: translateX(-50%);

    background-color: var(--color-gray-1000);
    transition: v-bind('pad.transition');
  }
}

.wrapper {
  margin: 0 8px 8px 0;

  display: flex;
  align-items: center;
}

.button {
  padding: 0 8px;
  height: 32px;
  max-width: 200px;

  display: flex;
  align-items: center;

  color: var(--color-gray-500);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight-bold);

  transition: color var(--transition-fast);

  &:not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    color: var(--color-gray-700);

    .icon {
      fill: var(--color-gray-700);
    }
  }

  &._active {
    color: var(--color-gray-1000);

    .icon {
      fill: var(--color-gray-1000);
    }
  }
}

.title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icon {
  // TODO: Перекрытие стилей
  width: auto;
  height: auto;

  transition: fill var(--transition-fast);
}

.content {
  flex-grow: 1;
  position: relative;
  overflow: hidden;

  height: v-bind(contentHeightStyle);

  transition: height var(--transition);
}
</style>
