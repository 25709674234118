import HttpService from '@/services/api/http.service';
import {Parameter} from '@/common/models/parameter';
import CacheGroup from '@/common/consts/cacheGroup';
import {DEFAULT_LINK_TYPE} from '@/services/api/document-master/document-master.consts';
import {LinkTypeOption} from '@/common/models/option/link-type-option';
import TextOption from '@/common/models/option/text-option';
import UserOption from '@/common/models/option/user-option';
import {DateTime} from 'luxon';
import {DocumentEndPoint, LinkEndPoint, ProjectEndPoint, SystemEndPoint, UserEndPoint} from '@/common/consts/endPoint';
import {GET_HINTS_COUNT} from '@/services/api/document/document-api.consts.ts';
import store from '@/store';
import {TABLE_SORT_ASC} from '@/configs/tables/table';
import TypeOption from '@/common/models/option/type-option';
import {TypeCode} from '@/common/consts/document-master/common';
import ProjectOption from '@/common/models/option/project-option';

export default class DocumentMasterApiService {
  static async getTypes(signal) {
    const dbAdapter = store.getters['server/dbAdapter'];
    const nameKey = dbAdapter.generateKey('Название');

    const response = await HttpService.post(DocumentEndPoint.GetTypes, {
      orderby: `${nameKey} ${TABLE_SORT_ASC}`,
    }, signal, true, {group: CacheGroup.DocumentMaster, key: `MASTER_TYPES`});

    return response.items
      .map(item => new TypeOption(item))
      .filter(type => type.code !== TypeCode.Route);
  }

  static async getProjects(signal) {
    const dbAdapter = store.getters['server/dbAdapter'];
    const nameKey = dbAdapter.generateKey('Название');

    const response = await HttpService.post(ProjectEndPoint.GetList, {
      orderby: `${nameKey} ${TABLE_SORT_ASC}`,
    }, signal, true, {group: CacheGroup.DocumentMaster, key: `MASTER_PROJECTS`});

    return response.items
      .map(item => new ProjectOption(item))
      .filter(project => !project.disabled);
  }

  static async getParameters(typeId, signal) {
    const response = await HttpService.post(DocumentEndPoint.GetTypeParameters, {
      DT_ID: typeId,
    }, signal);

    return response.items.map(item => new Parameter(item));
  }

  static async getParameterOptions(typeId, number, signal) {
    const response = await HttpService.post(DocumentEndPoint.GetTypeParameterOptions, {
      dt_id: typeId,
      number,
    }, signal, true, {group: CacheGroup.DocumentMaster, key: `MASK_PARAMETER_${typeId}_${number}`});

    return response.items.map(item => new TextOption(item));
  }

  static async getHints(request, signal) {
    const response = await HttpService.post(DocumentEndPoint.GetTypeParameterOptionHints, {
      dt_id: request.id,
      number: request.number,
      count: GET_HINTS_COUNT,
      value: request.query,
    }, signal);

    return response.items.map(item => new TextOption(item));
  }

  static async getLinkTypes(linkTypesIds = [], tableId, signal) {
    if (!linkTypesIds.length) {
      return [DEFAULT_LINK_TYPE];
    }

    const response = await HttpService.post(LinkEndPoint.GetLinkTypes, {
      where: `LT_ID IN (${linkTypesIds.join(',')})`,
    }, signal, true, {group: CacheGroup.DocumentMaster, key: `LINK_TYPES_${linkTypesIds.join('_')}`});

    const options = response.items.map(item => new LinkTypeOption(item));

    if (!tableId) {
      return [DEFAULT_LINK_TYPE, ...options];
    }

    return [
      DEFAULT_LINK_TYPE,
      ...options.filter(option => option.tableIds.includes(tableId)),
    ];
  }

  static async getActiveUsers(signal) {
    const response = await HttpService.post(
      UserEndPoint.GetListActive,
      {},
      signal,
      true,
      {group: CacheGroup.DocumentMaster, key: `USERS`},
    );

    return response.items.map(user => new UserOption(user));
  }

  static async getDeadline(days, signal) {
    const response = await HttpService.post(SystemEndPoint.GetDeadline, {
      count_day: days,
    }, signal);

    return DateTime.fromFormat(response.date, 'dd.MM.yyyy').toFormat('yyyy-MM-dd');
  }
}
