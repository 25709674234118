import LinkCard from '@/common/models/card/link-card';

export default {
  namespaced: true,
  state: {
    clients: [],
  },
  actions: {
    set({commit, dispatch}, payload) {
      if (payload.length > 0) {
        dispatch('master/steps/enable', 'clients', {root: true});
      }
      commit('set', payload);
    },
  },
  mutations: {
    set(state, payload) {
      state.clients = payload;
    },
    clearLinkTypes(state) {
      state.clients = state.clients.map(LinkCard.resetLinkType);
    },
    clear(state) {
      state.clients = [];
    },
  },
};
