import STEP_FILES from '@/common/consts/document-master/steps/stepFiles';
import STEP_LINKS from '@/common/consts/document-master/steps/stepLinks';
import STEP_BARCODE from '@/common/consts/document-master/steps/stepBarcode';
import STEP_CLIENTS from '@/common/consts/document-master/steps/stepClients';
import STEP_RESOLUTION from '@/common/consts/document-master/steps/stepResolution';
import STEP_TYPE from '@/common/consts/document-master/steps/stepType';
import {StepContentCreator} from '@/common/consts/document-master/steps/stepContent';
import STEP_DEADLINE from '@/common/consts/document-master/steps/stepDeadline';
import STEP_ROUTE from '@/common/consts/document-master/steps/stepRoute';
import StepContractorCreator from '@/common/consts/document-master/steps/stepContractor';
import STEP_EXECUTION from '@/common/consts/document-master/steps/stepExecution';
import STEP_LAUNCH from '@/common/consts/document-master/steps/stepLaunch';
import type from '@/store/modules/master/type';
import files from '@/store/modules/master/files';
import deadline from '@/store/modules/master/deadline';
import controller from '@/store/modules/master/controller';
import steps from '@/store/modules/master/steps';
import contractors from '@/store/modules/master/contractors';
import links from '@/store/modules/master/links';
import clients from '@/store/modules/master/clients';
import comment from '@/store/modules/master/comment';
import parameters from '@/store/modules/master/parameters';
import content from '@/store/modules/master/content';

export default {
  namespaced: true,
  modules: {
    type,
    content,
    parameters,
    deadline,
    links,
    clients,
    comment,
    files,
    controller,
    steps,
    contractors,
  },
  state: {
    isModalOpen: false,
    isWarningOpen: false,
    isLoading: false, // TODO: Отказаться от использования общего лоадера
    page: 0,
    controlsDisabled: false,
    isCreated: false,
    showForceClose: false,
    functions: {},
  },
  getters: {
    steps({contractors, steps}, getters) {
      const enabled = steps.steps;

      return [
        STEP_TYPE,
        StepContentCreator.create(getters['type/contentTitle']),
        STEP_DEADLINE,
        enabled.barcode && STEP_BARCODE,
        enabled.files && STEP_FILES,
        enabled.links && STEP_LINKS,
        enabled.clients && STEP_CLIENTS,
        enabled.resolution && STEP_RESOLUTION,
        STEP_ROUTE,
        ...StepContractorCreator.createList(contractors.contractors),
        STEP_EXECUTION,
        STEP_LAUNCH,
      ].filter(step => !!step);
    },
    step(state, getters) {
      return getters.steps[state.page];
    },
    header(_, getters) {
      return {
        title: getters.step.title,
        tooltip: getters.step.tooltip,
        dot: getters.step.dot,
      };
    },
    menu(_, getters) {
      return getters.steps.map(step => step.menuTitle);
    },
    isFirstPage(state) {
      return state.page === 0;
    },
    isLastPage(state, getters) {
      return state.page === getters.steps.length - 1;
    },
    isDirty(state, getters) {
      return state.page > 0 || getters['type/isDirty'];
    },
  },
  actions: {
    show({commit, dispatch}) {
      commit('setShowModal', true);
      dispatch('controller/setUser');
    },
    close({dispatch}) {
      dispatch('clear');
    },
    cancel({getters, commit, dispatch}) {
      if (getters.isDirty) {
        commit('setWarningOpen', true);
      } else {
        dispatch('clear');
      }
    },
    back({commit, state}) {
      commit('setPage', state.page - 1);
    },
    next({commit, state}) {
      if (state.functions.validate()) {
        commit('setPage', state.page + 1);
      }
    },
    ready({state}) {
      state.functions.ready();
    },
    goTo({state, commit}, payload) {
      if (payload === state.page) {
        return;
      }

      if (payload < state.page) {
        commit('setPage', payload);
      } else {
        if (state.functions.validate()) {
          commit('setPage', payload);
        }
      }
    },
    clear({commit}) {
      commit('cache/clear', null, {root: true});
      commit('type/clear');
      commit('content/clear');
      commit('parameters/clear');
      commit('deadline/clear');
      commit('links/clear');
      commit('clients/clear');
      commit('comment/clear');
      commit('files/clear');
      commit('controller/clear');
      commit('steps/clear');
      commit('contractors/clear');
      commit('clear');
    },
  },
  mutations: {
    setShowModal(state, payload) {
      state.isModalOpen = payload;
    },
    setWarningOpen(state, payload) {
      state.isWarningOpen = payload;
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setControlsDisabled(state, payload) {
      state.controlsDisabled = payload;
    },
    setCreated(state) {
      state.isCreated = true;
    },
    setShowForceClose(state, payload) {
      state.showForceClose = payload;
    },
    setFunctions(state, payload) {
      state.functions = payload;
    },
    clear(state) {
      state.isModalOpen = false;
      state.isWarningOpen = false;
      state.isLoading = false;
      state.page = 0;
      state.controlsDisabled = false;
      state.isCreated = false;
      state.showForceClose = false;
      state.functions = {};
    },
  },
};
