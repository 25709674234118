<template>
  <ul class="tile-list-ui">
    <tile-ui
      v-for="option in options"
      :key="option.code"
      tag="li"
      :label="option.label"
      :icon="option.icon"
      :checked="modelValue?.[option.code]"
      :disabled="option.disabled"
      @click="onClick(option)"
    ></tile-ui>
  </ul>
</template>

<script>
import {defineComponent} from 'vue';
import TileUi from '@/components/ui/TileUi.vue';
import {keys, only} from '@/common/utils/props-validators';

export default defineComponent({
  name: 'TileListUi',
  components: {
    TileUi,
  },
  model: {
    prop: 'modelValue',
    event: 'update:model-value',
  },
  props: {
    modelValue: Object,
    type: {
      type: String,
      default: 'buttons',
      validator: only('buttons', 'checkbox'),
    },
    options: {
      type: Array,
      required: true,
      validator: keys('label', 'code'),
    },
  },
  emits: ['update:model-value'],
  methods: {
    onClick(option) {
      if (this.type === 'buttons') {
        option.onClick();
      } else {
        this.$emit('update:model-value', {
          ...this.modelValue,
          [option.code]: !this.modelValue[option.code],
        });
      }
    },
  },
});
</script>

<style scoped lang="scss">
.tile-list-ui {
  margin: 0 -8px -8px 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tile-ui {
  flex: 0 0 calc(#{100% / 3} - 8px);
  margin: 0 8px 8px 0;
}
</style>
