import StepDeadline from '@/components/document-master/steps/StepDeadline.vue';
import {StepCode} from '@/common/consts/document-master/common';

export default {
  code: StepCode.Deadline,
  title: 'Определите срок и контролера',
  tooltip: 'Выберите срок документа и его контролера. Документ может быть без срока, но у поручений/согласований/уведомлений по нему срок есть всегда',
  menuTitle: 'Срок и контролер',
  component: StepDeadline,
};
