<template>
  <drop-zone-ui class="files-upload" text-size="l" @drop="add">
    <input ref="input" hidden type="file" multiple @change="onChange">

    <tabs-ui v-if="modelValue.length" ref="tabs" with-add full-height @add="dispatchChange">
      <template #title="{active, file}">
        <component :is="file.icon" class="icon icon_prefix-s" :class="{_active: active}"></component>
        <file-truncated-name :file="file"></file-truncated-name>
      </template>

      <tab-ui v-for="file in modelValue" :key="file.id" :file="file">
        <file-view
          :parent-id="parentId"
          :parent-table-id="parentTableId"
          :model-value="file"
          @update:model-value="onFileChange"
        ></file-view>
      </tab-ui>
    </tabs-ui>

    <file-empty v-else @add="dispatchChange"></file-empty>
  </drop-zone-ui>
</template>

<script>
import {defineComponent} from 'vue';
import TabUi from '@/components/ui/TabUi.vue';
import FileView from '@/components/document-master/FileView.vue';
import TabsUi from '@/components/ui/TabsUi.vue';
import UploadIcon from '@/assets/svg/upload.svg?component';
import CreateDocIcon from '@/assets/svg/create-doc.svg?component';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import DropZoneUi from '@/components/ui/DropZoneUi.vue';
import abort from '@/mixins/abort';
import FileEmpty from '@/components/document-master/FileEmpty.vue';
import FileError from '@/components/doc/files/FileError.vue';
import FileTruncatedName from '@/components/doc/files/FileTruncatedName.vue';
import upload from '@/mixins/upload';

export default defineComponent({
  name: 'FilesUpload',
  mixins: [upload, abort],
  components: {
    FileTruncatedName,
    FileError,
    FileEmpty,
    DropZoneUi,
    ButtonUi,
    TabsUi,
    FileView,
    UploadIcon,
    TabUi,
    CreateDocIcon,
  },
  props: {
    parentTableId: {
      type: Number,
      required: true,
    },
    parentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      filesCount: 0,
    };
  },
  methods: {
    beforeAdd() {
      this.filesCount = this.modelValue.length;
    },
    afterAdd() {
      this.$nextTick(() => this.$refs.tabs?.setActive(this.filesCount));
    },
    onFileChange(file) {
      const newValue = this.modelValue.map(current => current.id === file.id ? file : current);
      this.$emit('update:model-value', newValue);
    },
  },
});
</script>

<style scoped lang="scss">
.icon {
  flex-shrink: 0;
}

.file-view {
  height: 100%;
}
</style>
