<template>
  <div class="select-doc-route-step-master">
    <form-builder
      ref="form"
      :fields="fields"
      v-model="formDataProxy"
      @change="change"
    ></form-builder>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import step from '@/mixins/document-master/step';
import FormBuilder from '@/components/form/FormBuilder.vue';
import {RADIO_CARDS, TYPE_BOOL, TYPE_SEARCH, TYPE_SELECT, TYPE_SUB_TEXT} from '@/configs/form';
import {COPY_ROUTE, NEW_ROUTE, NONE_ROUTE, STANDARD_ROUTE} from '@/configs/routeTypes';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import VuexAdapter from '@/services/vuexAdapter';
import {DOCS_GET, ROUTES_RESPONSIBLES_UNSPECIFIED, ROUTES_STANDARD} from '@/configs/endPoints';
import {TABLE_SORT_ASC} from '@/configs/tables/table';
import dbAdapter from '@/mixins/dbAdapter';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import {ErrorHelper} from '@/services/errorHelper';
import Option from '@/common/models/option/option';

const notify = (value, color = '#FF4E41') => (`
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" rx="8" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4298 17.7511L12.4329 5.74819C12.24 5.41727 11.7619 5.41727 11.569 5.7482L4.57214 17.7511C4.37783 18.0845 4.61828 18.5029 5.00411 18.5029H18.9978C19.3836 18.5029 19.6241 18.0845 19.4298 17.7511ZM13.7288 4.99278C12.9572 3.66907 11.0447 3.66908 10.2731 4.99278L3.27625 16.9957C2.49902 18.329 3.46079 20.0029 5.00411 20.0029H18.9978C20.5411 20.0029 21.5029 18.329 20.7257 16.9957L13.7288 4.99278Z" fill="${color}"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8551 9.74664C12.9236 9.35705 12.6239 9 12.2283 9C11.9096 9 11.64 9.23578 11.5976 9.55166L11 14H12.107L12.8551 9.74664ZM11.5 17C12.3284 17 13 16.5523 13 16C13 15.4477 12.3284 15 11.5 15C10.6716 15 10 15.4477 10 16C10 16.5523 10.6716 17 11.5 17Z" fill="${color}"/>
  </svg>
  ${value}
`);

export default defineComponent({
  name: 'StepRoute',
  mixins: [step, dbAdapter],
  components: {FormBuilder},
  data() {
    return {
      NEW_ROUTE,
      standardRoutes: [],
      fields: [
        {
          type: RADIO_CARDS,
          name: 'type_route',
          options: [
            {
              code: STANDARD_ROUTE,
              label: 'Стандартный',
            },
            {
              code: NEW_ROUTE,
              label: 'Новый',
            },
            {
              code: COPY_ROUTE,
              label: 'Копировать',
              activeColor: '#FFDB65',
            },
            {
              code: NONE_ROUTE,
              label: 'Без маршрута',
              activeColor: '#FFBCBC',
            },
          ],
        },
        {
          type: TYPE_SELECT,
          name: 'route_doc_id',
          placeholder: 'Выберите маршрут из списка',
          options: () => this.standardRoutes,
          required: true,
          classes: ['mt-4'],
          hidden: () => this.formDataProxy.type_route !== STANDARD_ROUTE,
        },
        {
          type: TYPE_SEARCH,
          name: 'route_copy_doc_id',
          placeholder: 'Поиск документа',
          required: true,
          endPoint: DOCS_GET,
          keyField: 'DOC_ID',
          valueField: 'Содержание',
          queryFormatter: (v) => {
            const getFieldName = this.dbAdapter[this.generateKey];

            const where = Number(v)
              ? `${getFieldName('Содержание')} like '%${v}%' or ${getFieldName('DOC_ID')} = '${v}'`
              : `${getFieldName('Содержание')} like '%${v}%'`;

            return {
              where,
              orderby: `${getFieldName('Содержание')} ${TABLE_SORT_ASC}`,
            };
          },
          labelFormatter: (item) => {
            return `№${item['DOC_ID']} ${item['Содержание']}`;
          },
          classes: ['mt-4'],
          hidden: () => this.formDataProxy.type_route !== COPY_ROUTE,
        },
        {
          type: TYPE_SUB_TEXT,
          label: notify('Маршрут не будет создан по результатам работы данного мастера. Вы должны будете сделать это самостоятельно в карточке документа.', '#ffd130'),
          classes: ['mt-5'],
          hidden: () => this.formDataProxy.type_route !== NEW_ROUTE,
        },
        {
          type: TYPE_BOOL,
          name: 'route_none',
          label: 'Я понимаю, что это значит',
          required: true,
          classes: ['mt-5'],
          hidden: () => this.formDataProxy.type_route !== NONE_ROUTE,
        },
        {
          type: TYPE_SUB_TEXT,
          label: notify('Документ, который вы создаете, будет остановлен автоматически. Вы не сможете его править.'),
          classes: ['mt-4'],
          hidden: () => this.formDataProxy.type_route !== NONE_ROUTE,
        },
      ],
    };
  },
  async created() {
    this.showLoader();
    this.standardRoutes = await this.getStandardRoutes();
    this.hideLoader();
  },
  computed: {
    ...mapState('master/type', ['type', 'project']),
    ...mapState('master/parameters', {parametersModel: 'model'}),
    ...mapState('master/content', ['content']),
    ...mapGetters('master/deadline', ['deadline']),
  },
  methods: {
    ...mapMutations('master/contractors', {setContractors: 'set'}),
    ...mapActions({
      fetchStandardRoutesAction: VuexAdapter.getNameAction(ROUTES_STANDARD),
      fetchRoutesResponsibleUnspecifiedAction: VuexAdapter.getNameAction(ROUTES_RESPONSIBLES_UNSPECIFIED),
    }),
    getStandardRoutes() {
      return this.fetchStandardRoutesAction().then(r => {
        return r.data.items.map(item => {
          return {
            code: item['DOC_ID'],
            label: item['Содержание'],
          };
        });
      });
    },
  },
  watch: {
    'modelValue.route_doc_id.code': {
      handler(routeDocId) {
        if (!routeDocId) {
          this.$set(this.formDataProxy, 'routes_responsible_unspecified_steps', []);
          this.change();
          this.setContractors([]);
          return;
        }

        this.showLoader();

        const request = {
          doc_id: routeDocId,
          substitutions: {
            'DT_ID': this.type.code,
            'Содержание': this.content,
            'Срок': this.deadline,
            'Проект': this.project.label,
            'Рег.номер': this.formDataProxy.reg_number,
            'Дата': this.formDataProxy.reg_date,
            'MASK': null,
          },
        };

        if (this.type.mask) {
          request['substitutions']['MASK'] = this.type.mask;

          for (const maskFormDataKey in this.parametersModel) {
            const parameter = this.parametersModel[maskFormDataKey];
            request['substitutions']['MASK_PARAM' + maskFormDataKey]
              = parameter instanceof Option ? parameter.code : parameter;
          }
        }

        this.fetchRoutesResponsibleUnspecifiedAction(request).then(r => {
          this.$set(this.formDataProxy, 'routes_responsible_unspecified_steps', r.data.items);
          this.change();
          this.setContractors(r.data.items);
        }).catch(error => {
          this.$notify({
            title: 'Ошибка получения параметров маршрута',
            type: ERROR_NOTIFY_TYPE,
            text: ErrorHelper.format(error),
          });
        }).finally(() => {
          this.hideLoader();
        });
      },
      immediate: true,
    },
  },
});
</script>
