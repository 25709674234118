export class Card {
  constructor(response, idKey, titleKey, icon, subtitleKey) {
    this.id = response[idKey];
    this.title = response[titleKey];
    this.icon = icon;

    if (subtitleKey) {
      this.subtitle = response[subtitleKey];
    }
  }
}
