import DocumentApiService from '@/services/api/document/document-api.service';
import {PREVIEW_FILES_EXTENSIONS} from '@/common/consts/preview.consts';

export default {
  namespaced: true,
  state: {
    list: [],
    isLoading: false,
    error: null,
    controller: null,
  },
  getters: {
    isReady(state) {
      return !state.isLoading && !state.error;
    },
    lastRevisionList(state) {
      return state.list.filter(file => file.isLast);
    },
    oldRevisionList(state) {
      return state.list.filter(file => !file.isLast);
    },
    getOldRevisionListByName: (_, getters) => name => {
      return getters.oldRevisionList.filter(file => file.name === name);
    },
    previewLastRevisionList(_, getters) {
      return getters.lastRevisionList.filter(file => PREVIEW_FILES_EXTENSIONS.includes(file.extension));
    },
  },
  actions: {
    async fetch({commit, state}, payload) {
      state.controller?.abort();
      const controller = new AbortController();
      commit('startFetch', controller);
      try {
        const list = await DocumentApiService.getFiles(payload, controller.signal);
        commit('finishFetch', {list});
      } catch (error) {
        commit('finishFetch', {error});
      }
    },
  },
  mutations: {
    clear(state) {
      state.list = [];
      state.isLoading = false;
      state.error = null;
      state.controller = null;
    },
    startFetch(state, payload) {
      state.list = [];
      state.isLoading = true;
      state.error = null;
      state.controller = payload;
    },
    finishFetch(state, payload) {
      state.list = payload.list || [];
      state.isLoading = false;
      state.error = payload.error || null;
      state.controller = null;
    },
  },
};
