<template>
  <span
    class="file-truncated-name"
    :aria-label="file.name"
    data-tooltip-position="top"
    data-tooltip-size="auto"
    :role="showTooltip && 'tooltip'"
    @mouseenter="setShowTooltip"
  >
    <span ref="title" class="title">{{ file.title }}</span>
    <span>.{{ file.extension }}</span>
  </span>
</template>

<script>
import {defineComponent} from 'vue';
import {BaseFile} from '@/common/models/file/base-file';

export default defineComponent({
  name: 'FileTruncatedName',
  props: {
    file: {
      type: BaseFile,
      required: true,
    },
  },
  data() {
    return {
      showTooltip: false,
    };
  },
  methods: {
    setShowTooltip() {
      this.showTooltip = this.$refs.title.scrollWidth > this.$refs.title.clientWidth + 1;
    },
  },
});
</script>

<style scoped lang="scss">
.file-truncated-name {
  min-width: 0;
  display: flex;
  white-space: nowrap;

  &::after {
    /* TODO: Избавиться от !important */
    width: 230px !important;
    word-wrap: break-word;
  }
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
