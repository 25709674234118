<template>
  <loader-ui v-if="page.state === State.Loading" position="static" color="white" text="Загрузка данных"></loader-ui>

  <file-error v-else-if="page.state === State.Error" @retry="$emit('retry')"></file-error>

  <img
    v-else-if="page.state === State.Defined"
    class="image"
    :src="page.url"
    :alt="page.alt"
    @load="$emit('load')"
  >
</template>

<script>
import {defineComponent} from 'vue';
import FileError from '@/components/doc/files/FileError.vue';
import {State} from '@/common/consts/state.consts';
import LoaderUi from '@/components/ui/LoaderUi.vue';

export default defineComponent({
  name: 'FilePreviewPage',
  components: {
    LoaderUi,
    FileError,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  emits: ['load', 'retry'],
  data() {
    return {
      isLoading: false,
      isError: false,
      State,
    };
  },
});
</script>

<style scoped lang="scss">
.image {
  display: block;
  width: 100%;
}
</style>
