<template>
  <div ref="dropZoneRef" class="drop-zone-ui">
    <slot></slot>

    <transition name="opacity">
      <div v-if="isOverDropZone" class="overlay">
        <p class="text" :class="`_size-${textSize}`">Перетащите сюда файл</p>
      </div>
    </transition>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import {useDropZone} from '@vueuse/core';
import {keys} from '@/common/utils/props-validators';

defineProps({
  textSize: {
    type: String,
    default: 'm',
    validator: keys('m', 'l'),
  },
});

const emit = defineEmits(['drop']);

const dropZoneRef = ref(null);

const {isOverDropZone} = useDropZone(dropZoneRef, {
  onDrop: files => emit('drop', files),
});
</script>

<style scoped lang="scss">
.drop-zone-ui {
  position: relative;
}

.overlay {
  position: absolute;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--color-drop);
}

.text {
  margin-bottom: 0; // TODO: Перекрытие стилей
  color: var(--color-black);
  opacity: 0.7;

  &._size-m {
    font-size: var(--font-size-xxl);
    line-height: var(--line-height-xxl);
  }

  &._size-l {
    font-size: var(--font-size-xxxl);
    line-height: var(--line-height-xxxl);
  }
}
</style>
