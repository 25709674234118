<template>
  <component :is="tag" class="card-ui">
    <header class="header">
      <component
        :is="item.href ? 'a' : 'span'"
        class="wrapper"
        :class="{_bold: !item.href}"
        :href="item.href"
        :target="item.href && '_blank'"
        :aria-label="item.title"
        data-tooltip-position="bottom"
        data-tooltip-size="auto"
        :role="showTooltip && 'tooltip'"
        @mouseenter="setShowTooltip"
      >
        <span ref="title" class="title _bold">{{ item.title }}</span>
        <span v-if="item.subtitle">{{ item.subtitle }}</span>
      </component>

      <button-icon-ui @click="$emit('remove')">
        <close-icon></close-icon>
      </button-icon-ui>
    </header>

    <component :is="icon" class="alphadoc-icon" width="60px" height="60px"></component>

    <component :is="footerComponent" v-bind="item" @change="$emit('change', $event)"></component>
  </component>
</template>

<script>
import {defineComponent} from 'vue';
import CloseIcon from '@/assets/svg/close.svg?component';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import {keys, only} from '@/common/utils/props-validators';

export default defineComponent({
  name: 'CardUi',
  components: {
    CloseIcon,
    ButtonIconUi,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
      validator: only('div', 'li'),
    },
    icon: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
      validator: keys('title'),
    },
    footerComponent: Object,
  },
  emits: ['change', 'remove'],
  data() {
    return {
      showTooltip: false,
    };
  },
  methods: {
    setShowTooltip() {
      this.showTooltip = this.$refs.title.scrollHeight > this.$refs.title.clientHeight + 1;
    },
  },
});
</script>

<style scoped lang="scss">
.card-ui {
  padding: 4px;

  display: flex;
  flex-direction: column;

  border-radius: 8px;
  background-color: var(--color-gray-075);
}

.header {
  flex-grow: 1;
  margin-bottom: 4px;
  padding: 4px;
  min-height: 40px;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.wrapper {
  min-width: 0;

  &::after {
    /* TODO: Избавиться от !important */
    width: 100% !important;
    word-wrap: break-word;
  }

  &._bold {
    font-weight: var(--font-weight-bold);
  }
}

.title {
  margin-right: 4px;
  max-height: 32px;

  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;

  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.button-icon-ui {
  flex-shrink: 0;
  margin: -4px;
}

.alphadoc-icon {
  fill: var(--color-gray-200);

  align-self: center;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 32px;
  }
}
</style>
