import {BaseFile} from '@/common/models/file/base-file';
import {uniqueId} from 'lodash-es';

export default class ExternalFile extends BaseFile {
  constructor(file, base64) {
    super(uniqueId('external-file-'), file.name, file.size);
    this.base64 = base64;
  }

  static async createList(files) {
    return Promise.all(files.map(async file => {
      const base64 = await ExternalFile._toBase64(file);
      return new ExternalFile(file, base64);
    }));
  }

  static async _toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split('base64,')[1]);
      reader.onerror = error => reject(error);
    });
  }
}
