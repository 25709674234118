<template>
  <div class="file-empty">
    <create-doc-icon class="icon"></create-doc-icon>

    <button-ui color="black" mode="outline" @click="$emit('add')">
      <template #prefix>
        <upload-icon></upload-icon>
      </template>
      <span>Загрузить файлы</span>
    </button-ui>

    <p class="info">
      Выберите и загрузите файл с компьютера или перетащите в это поле
    </p>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import UploadIcon from '@/assets/svg/upload.svg?component';
import CreateDocIcon from '@/assets/svg/create-doc.svg?component';

export default defineComponent({
  name: 'FileEmpty',
  components: {
    CreateDocIcon,
    UploadIcon,
    ButtonUi,
  },
  emits: ['add'],
});
</script>

<style scoped lang="scss">
.file-empty {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.01em;
}

.icon {
  margin-bottom: 24px;

  /* TODO: Перекрытие стилей */
  width: auto;
  height: auto;
}

.button-ui {
  margin-bottom: 24px;
}

.info {
  width: 340px;
  text-align: center;
}
</style>
