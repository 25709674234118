<template>
  <div class="deadline-ui">
    <switch-input-form
      :options="SWITCH_OPTIONS"
      :model-value="modelValue.type"
      @change="onTypeChange"
    ></switch-input-form>

    <div class="controls" v-if="modelValue.type === DeadlineType.WorkingDays">
      <number-input-form
        :min="1"
        :max="360"
        :model-value="modelValue.workingDays"
        @change="onWorkingDaysChange"
      ></number-input-form>

      <date-form only-input disabled :value="modelValue.workingDaysDate"></date-form>
    </div>

    <div class="controls" v-else-if="modelValue.type === DeadlineType.Days">
      <number-input-form
        :min="1"
        :max="360"
        :model-value="modelValue.days"
        @change="onDaysChange"
      ></number-input-form>

      <date-form only-input disabled :value="modelValue.daysDate"></date-form>
    </div>

    <date-form
      class="_single"
      v-else-if="modelValue.type === DeadlineType.Date"
      :error="error"
      :value="modelValue.date"
      @change="onDateChange"
    ></date-form>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import SwitchInputForm from '@/components/form/SwitchInputForm.vue';
import NumberInputForm from '@/components/form/NumberInputForm.vue';
import DateForm from '@/components/form/DateForm.vue';
import {DateTime} from 'luxon';
import DocumentMasterApiService from '@/services/api/document-master/document-master-api.service';
import abort from '@/mixins/abort';
import {CanceledError} from 'axios';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import {ErrorHelper} from '@/services/errorHelper';
import {debounce} from 'lodash-es';
import {INPUT_DEBOUNCE} from '@/common/consts/control.consts';
import {DeadlineType} from '@/common/consts/deadline';
import {keys} from '@/common/utils/props-validators';

export default defineComponent({
  name: 'DeadlineUi',
  mixins: [abort],
  model: {
    prop: 'modelValue',
    event: 'update:model-value',
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
      validator: keys('type', 'workingDays', 'workingDaysDate', 'days', 'daysDate', 'date'),
    },
    error: String,
  },
  emits: ['update:model-value'],
  components: {
    DateForm,
    NumberInputForm,
    SwitchInputForm,
  },
  data() {
    return {
      getDeadlineDebounced: this.getDeadline,
      SWITCH_OPTIONS: [
        {
          label: 'Без срока',
          value: DeadlineType.None,
        },
        {
          label: 'В рабочих днях',
          value: DeadlineType.WorkingDays,
        },
        {
          label: 'В днях',
          value: DeadlineType.Days,
        },
        {
          label: 'Дата',
          value: DeadlineType.Date,
        },
      ],
      DeadlineType,
    };
  },
  created() {
    this.getDeadlineDebounced = debounce(this.getDeadline, INPUT_DEBOUNCE);
  },
  mounted() {
    if (!this.modelValue.workingDaysDate) {
      this.getDeadlineDebounced(this.modelValue.workingDays);
    }
    if (!this.modelValue.daysDate) {
      this.onDaysChange(this.modelValue.days);
    }
  },
  unmounted() {
    this.getDeadlineDebounced.cancel();
  },
  methods: {
    onTypeChange(type) {
      this.update({type});
    },
    onWorkingDaysChange(workingDays) {
      this.update({workingDays});
      this.getDeadlineDebounced(workingDays);
    },
    onDaysChange(days) {
      const daysDate = DateTime.local().plus({days}).toFormat('yyyy-MM-dd');
      this.update({days, daysDate});
    },
    onDateChange(date) {
      this.update({date});
    },
    update(data) {
      this.$emit('update:model-value', {...this.modelValue, ...data});
    },
    async getDeadline(workingDays) {
      this.abort('Получено новое значение');

      try {
        const workingDaysDate = await DocumentMasterApiService.getDeadline(workingDays, this.abortController.signal);
        this.update({workingDaysDate});
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }

        this.$notify({
          title: 'Ошибка получения срока в рабочих днях',
          type: ERROR_NOTIFY_TYPE,
          text: ErrorHelper.format(error, false),
        });
      }
    },
  },
});
</script>

<style scoped lang="scss">
.form--switch-input {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.controls {
  display: flex;
}

.form--input-number {
  flex: 1 1 50%;

  margin-bottom: 0; // TODO: Отказаться от перекрытия стилей
  margin-right: 8px;
}

.form--date {
  flex: 1 1 50%;
  min-width: 166px;

  margin-bottom: 0; // TODO: Отказаться от перекрытия стилей

  &._single {
    max-width: 310px;
  }
}
</style>
