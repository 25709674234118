<template>
  <form-builder ref="form" :fields="fields" :error="warning" v-model="model"></form-builder>
</template>

<script>
import {defineComponent} from 'vue';
import step from '@/mixins/document-master/step';
import FormBuilder from '@/components/form/FormBuilder.vue';
import {mapGetters, mapMutations, mapState} from 'vuex';
import {COUNTERPARTIES_REGIONS_SEGMENTS__VIEW} from '@/configs/events';
import DocumentMasterFields from '@/common/consts/document-master/fields';

export default defineComponent({
  name: 'StepDeadline',
  mixins: [step],
  components: {
    FormBuilder,
  },
  computed: {
    ...mapState('master/controller', ['controller']),
    ...mapState('master/steps', ['steps']),
    ...mapGetters('master/deadline', {deadline: 'get'}),
    ...mapGetters('master/steps', ['warning']),
    ...mapGetters(['accessToEvent']),
    fields() {
      return [
        DocumentMasterFields.CONTROLLER,
        DocumentMasterFields.DEADLINE,
        DocumentMasterFields.steps(this.accessToEvent(COUNTERPARTIES_REGIONS_SEGMENTS__VIEW)),
      ];
    },
    model: {
      get() {
        return {
          controller: this.controller,
          deadline: this.deadline,
          steps: this.steps,
        };
      },
      set(value) {
        this.setController(value.controller);
        this.setDeadline(value.deadline);
        this.setSteps(value.steps);
      },
    },
  },
  methods: {
    ...mapMutations('master/controller', {setController: 'set'}),
    ...mapMutations('master/deadline', {setDeadline: 'set'}),
    ...mapMutations('master/steps', {setSteps: 'set'}),
  },
});
</script>

<style scoped lang="scss">
.form-builder {
  height: 100%;
}

// TODO: Отказаться от перекрытия стилей
:deep(.form__row--1) {
  margin-bottom: auto !important;
}
</style>
