<template>
  <preview-block class="file-preview" title="Просмотр" no-padding @resize="onResize">
    <template #title-postfix>
      <file-truncated-link :file="file" is-muted></file-truncated-link>
    </template>

    <template #header-postfix="{isOpen}">
      <div class="header-postfix">
        <transition name="opacity-fast">
          <file-preview-pagination
            v-if="isOpen && state === State.Defined && pages.length > 1"
            :current="current"
            :count="pages.length"
            @prev="$refs.carousel.goToPrev()"
            @next="$refs.carousel.goToNext()"
          ></file-preview-pagination>
        </transition>

        <button-ui v-if="showEdit" size="s" color="gray" mode="outline" @click="$refs.modal.show()">
          <template #prefix>
            <edit-icon></edit-icon>
          </template>
          <span v-if="showButtonTitle">Редактировать</span>
        </button-ui>

        <file-preview-edit
          ref="modal"
          :file="file"
          :pages="pages"
          :state="state"
          :initial="current"
          @fetch="loadRange"
          @retry="onRetry"
        ></file-preview-edit>
      </div>
    </template>

    <div class="body">
      <loader-ui
        v-if="state === State.Loading"
        position="static"
        color="white"
        text="Загрузка данных"
      ></loader-ui>

      <file-error v-else-if="state === State.Error" @retry="loadFile"></file-error>

      <carousel-ui ref="carousel" v-else-if="state === State.Defined" v-model="current" :slides="slides">
        <template #default="{item, index}">
          <file-preview-page
            :page="item"
            @load="onImageLoad(index + 1)"
            @retry="onRetry(index + 1)"
          ></file-preview-page>
        </template>
      </carousel-ui>
    </div>
  </preview-block>
</template>

<script>
import {defineComponent} from 'vue';
import FilePreviewPage from '@/components/doc/file-preview/FilePreviewPage.vue';
import FileError from '@/components/doc/files/FileError.vue';
import FileApiService from '@/services/api/file/file-api.service';
import DocumentFile from '@/common/models/file/document-file';
import {State} from '@/common/consts/state.consts.js';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';
import FileTruncatedLink from '@/components/doc/files/FileTruncatedLink.vue';
import FilePreviewEdit from '@/components/doc/file-preview/FilePreviewEdit.vue';
import FilePreviewPagination from '@/components/doc/file-preview/FilePreviewPagination.vue';
import {mapGetters, mapState} from 'vuex';
import VuexAdapter from '@/services/vuexAdapter';
import {DOCS_GET} from '@/configs/endPoints';
import {DOCUMENTS__EDIT} from '@/configs/events';
import previewBlock from '@/mixins/previewBlock';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import EditIcon from '@/assets/svg/edit.svg?component';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import CarouselUi from '@/components/ui/CarouselUi.vue';
import {range} from 'lodash-es';
import {PRELOAD_IMAGES_COUNT} from '@/common/consts/preview.consts';
import StorageApiService from '@/services/api/storage/storage-api.service';
import abort from '@/mixins/abort';

export default defineComponent({
  name: 'FilePreview',
  mixins: [abort, previewBlock],
  components: {
    CarouselUi,
    LoaderUi,
    EditIcon,
    ButtonUi,
    FilePreviewPagination,
    FilePreviewEdit,
    FileTruncatedLink,
    PreviewBlock,
    FilePreviewPage,
    FileError,
  },
  props: {
    file: {
      type: DocumentFile,
      required: true,
    },
  },
  data() {
    return {
      pages: [],
      current: 1,
      state: State.Undefined,
    };
  },
  mounted() {
    this.loadFile();
  },
  computed: {
    ...mapState('server', ['storageUrl']),
    ...mapGetters({
      accessToEvent: 'accessToEvent',
      docGetter: VuexAdapter.getNameRowGetter(DOCS_GET),
    }),
    showEdit() {
      return this.accessToEvent(DOCUMENTS__EDIT) && this.docGetter['DS_ID'] !== 5;
    },
    slides() {
      return this.pages.map((page, index) => ({
        ...page,
        key: page.url,
        alt: `${this.file.name}. Страница ${index + 1}`,
      }));
    },
    State() {
      return State;
    },
  },
  watch: {
    current(pageNumber) {
      this.loadRange(pageNumber);
    },
  },
  methods: {
    onImageLoad(pageNumber) {
      if (pageNumber === this.current) {
        this.$refs.carousel.recalculate();
      }
    },
    onRetry(pageNumber) {
      if (this.state === State.Defined) {
        this.loadRange(pageNumber);
      } else {
        this.loadFile();
      }
    },
    async loadFile() {
      this.state = State.Loading;

      try {
        this.addView();
        const pagesCount = await FileApiService.getPagesCount(this.file.apiId, this.file.hash, this.abortController.signal);
        this.pages = this.createPages(pagesCount);
        this.state = State.Defined;
        void this.loadRange(1);
      } catch (error) {
        this.state = State.Error;
      }
    },
    loadRange(pageNumber) {
      const start = Math.max(1, pageNumber - PRELOAD_IMAGES_COUNT);
      const end = Math.min(pageNumber + PRELOAD_IMAGES_COUNT + 1, this.pages.length + 1);

      const pages = range(start, end);
      const hasError = pages.some(pageNumber => this.pages[pageNumber - 1].state === State.Error);

      if (hasError) {
        this.addView();
      }

      pages.forEach(pageNumber => this.loadPage(pageNumber));
    },
    async loadPage(pageNumber) {
      const page = this.pages[pageNumber - 1];
      if (page.state === State.Defined || page.state === State.Loading) {
        return;
      }

      this.setPageState(State.Loading, pageNumber);
      try {
        await StorageApiService.load(page.url, this.abortController.signal);
        this.setPageState(State.Defined, pageNumber);
      } catch (error) {
        this.setPageState(State.Error, pageNumber);
      }
    },
    setPageState(state, pageNumber) {
      this.pages = this.pages.map(((page, i) => i === pageNumber - 1 ? {...page, state} : page));
    },
    createPages(pagesCount) {
      return range(1, pagesCount + 1)
        .map(page => `${this.storageUrl}${this.file.hash}_page${page.toString().padStart(4, '0')}.jpg`)
        .map(url => ({state: State.Undefined, url}));
    },
    addView() {
      try {
        FileApiService.addView(this.file.apiId, this.abortController.signal);
      } catch (error) {
        console.warn('Ошибка проигнорирована', error);
      }
    },
  },
});
</script>

<style scoped lang="scss">
.file-truncated-link {
  transform: translateY(1.5px);
}

.header-postfix {
  display: flex;
}

.loader-ui,
.file-error {
  min-height: 300px;
  aspect-ratio: 100 / 141;
}

.body {
  overflow: hidden;
  border-radius: 0 0 8px 8px;
}
</style>
