import { render, staticRenderFns } from "./ComboboxUi.vue?vue&type=template&id=642bbefd&scoped=true"
import script from "./ComboboxUi.vue?vue&type=script&lang=js"
export * from "./ComboboxUi.vue?vue&type=script&lang=js"
import style0 from "./ComboboxUi.vue?vue&type=style&index=0&id=642bbefd&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642bbefd",
  null
  
)

export default component.exports