import {BaseFile} from '@/common/models/file/base-file';
import Revision from '@/common/models/revision';
import {DateTime} from 'luxon';
import store from '@/store';

export default class ApiFile extends BaseFile {
  constructor(response, file) {
    super(
      file?.id || response['F_ID'],
      file?.name || response['Название'],
      file?.size,
    );
    this.apiId = response['F_ID'];
    this.hash = response['Файл'];
    this.pdfUrl = `${store.state.server.storageUrl}${this.hash}.pdf`;
    this.comment = response['Примечание'];
    this.revision = new Revision(response['Редакция']);
    this.createdDate = this._getCreatedDate(response['Создан']);
  }

  _getCreatedDate(date) {
    return new DateTime.fromSQL(date).toFormat('dd.MM.yyyy, HH:mm');
  }
}
