export const TYPE_STRING = 'TYPE_STRING';
export const TYPE_TEXT = 'TYPE_TEXT';
export const TYPE_PROMPT = 'TYPE_PROMPT';
export const TYPE_INTEGER = 'TYPE_INTEGER';
export const TYPE_TILE_LIST = 'TYPE_TILE_LIST';
export const TYPE_DATE = 'TYPE_DATE';
export const TYPE_BOOL = 'TYPE_BOOL';
export const TYPE_SLOT = 'TYPE_SLOT';
export const TYPE_FILE = 'TYPE_FILE';
export const TYPE_SUB_TEXT = 'TYPE_SUB_TEXT';
export const TYPE_SWITCH = 'TYPE_SWITCH';
export const TYPE_SELECT = 'TYPE_SELECT';
export const TYPE_SEARCH = 'TYPE_SEARCH';
export const TYPE_SEARCH_MULTIPLE = 'TYPE_SEARCH_MULTIPLE';
export const TYPE_CHECKBOX_SWITCH = 'TYPE_CHECKBOX_SWITCH';
export const TYPE_DEADLINE = 'TYPE_DEADLINE';
export const RADIO_CARDS = 'RADIO_CARDS';

export const TYPES_FORM = {
    [TYPE_STRING]: TYPE_STRING,
    [TYPE_TEXT]: TYPE_TEXT,
    [TYPE_PROMPT]: TYPE_PROMPT,
    [TYPE_INTEGER]: TYPE_INTEGER,
    [TYPE_TILE_LIST]: TYPE_TILE_LIST,
    [TYPE_DATE]: TYPE_DATE,
    [TYPE_BOOL]: TYPE_BOOL,
    [TYPE_SLOT]: TYPE_SLOT,
    [TYPE_FILE]: TYPE_FILE,
    [TYPE_SUB_TEXT]: TYPE_SUB_TEXT,
    [TYPE_SWITCH]: TYPE_SWITCH,
    [TYPE_SELECT]: TYPE_SELECT,
    [TYPE_SEARCH]: TYPE_SEARCH,
    [TYPE_SEARCH_MULTIPLE]: TYPE_SEARCH_MULTIPLE,
    [TYPE_CHECKBOX_SWITCH]: TYPE_CHECKBOX_SWITCH,
    [TYPE_DEADLINE]: TYPE_DEADLINE,
    [RADIO_CARDS]: RADIO_CARDS,
};
