import {TYPE_DEADLINE, TYPE_SEARCH_MULTIPLE, TYPE_SELECT, TYPE_TEXT, TYPE_TILE_LIST} from '@/configs/form';
import DocumentApiService from '@/services/api/document/document-api.service';
import DocumentMasterApiService from '@/services/api/document-master/document-master-api.service';
import AddLinkIcon from '@/assets/svg/add-link.svg?component';
import AddClientIcon from '@/assets/svg/add-client.svg?component';
import AttachmentIcon from '@/assets/svg/attachment.svg?component';
import FileLinkIcon from '@/assets/svg/file-link.svg?component';
import BarcodeIcon from '@/assets/svg/barcode.svg?component';
import ClientLinkIcon from '@/assets/svg/client-link.svg?component';
import ResolutionIcon from '@/assets/svg/resolution.svg?component';
import ClientApiService from '@/services/api/client/client-api.service';
import CardLinkTypes from '@/components/document-master/CardLinkTypes.vue';

export default class DocumentMasterFields {
  static TYPE = {
    type: TYPE_SELECT,
    name: 'type',
    placeholder: 'Тип',
    getOptions: DocumentMasterApiService.getTypes,
    noClear: true,
  };

  static CONTENT = {
    type: TYPE_TEXT,
    name: 'content',
    placeholder: 'Введите содержание документа',
    required: true,
    length: 255,
    rows: 12,
  };

  static DEADLINE = {
    type: TYPE_DEADLINE,
    name: 'deadline',
    required: true,
  };

  static LINKS = {
    type: TYPE_SEARCH_MULTIPLE,
    name: 'links',
    spoiler: 'Связанные документы',
    placeholder: 'Введите номер документа',
    addIcon: AddLinkIcon,
    addText: 'Добавить ссылки',
    getOptions: DocumentApiService.getOptions,
    getCard: DocumentApiService.getDocument,
    cardFooter: CardLinkTypes,
  };

  static CLIENTS = {
    type: TYPE_SEARCH_MULTIPLE,
    name: 'clients',
    spoiler: 'Контрагенты',
    placeholder: 'Поиск контрагента',
    addIcon: AddClientIcon,
    addText: 'Добавить контрагента',
    getOptions: ClientApiService.getOptions,
    getCard: ClientApiService.getClient,
    cardFooter: CardLinkTypes,
  };

  static COMMENT = {
    type: TYPE_TEXT,
    name: 'comment',
    spoiler: 'Примечание',
    length: 255,
    rows: 7,
    placeholder: 'Введите комментарий к документу',
  };

  static CONTROLLER = {
    type: TYPE_SELECT,
    name: 'controller',
    getOptions: DocumentMasterApiService.getActiveUsers,
    updateAfterLoad: true,
    noClear: true,
    required: true,
  };

  static project(filter) {
    return {
      type: TYPE_SELECT,
      name: 'project',
      placeholder: 'Проект',
      getOptions: DocumentMasterApiService.getProjects,
      filter,
      required: true,
    };
  }

  static parameters(parameters, typeId) {
    return parameters.map(parameter => [parameter.getFormField(typeId)]);
  }

  static steps(withClients) {
    return {
      type: TYPE_TILE_LIST,
      name: 'steps',
      options: [{
        code: 'files',
        label: 'Прикрепить файлы',
        icon: AttachmentIcon,
      }, {
        code: 'links',
        label: 'Связать этот документ',
        icon: FileLinkIcon,
      }, {
        code: 'barcode',
        label: 'Назначить штрих код',
        icon: BarcodeIcon,
      }, withClients && {
        code: 'clients',
        label: 'Добавить ссылки на контрагентов',
        icon: ClientLinkIcon,
      }, {
        code: 'resolution',
        label: 'Добавить резолюцию',
        icon: ResolutionIcon,
      },
      ].filter(step => !!step),
    };
  }
}
