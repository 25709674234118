import {INITIAL_ENABLED_STEPS} from '@/common/consts/document-master/common';
import FormError from '@/common/models/form-error';

export default {
  namespaced: true,
  state: {
    steps: INITIAL_ENABLED_STEPS,
  },
  getters: {
    warning(state, _, rootState) {
      const part = [
        state.steps.files === false && rootState.master.files.files.length && 'прикрепленные файлы',
        state.steps.links === false && rootState.master.links.links.length && 'ссылки на документы',
        state.steps.clients === false && rootState.master.clients.clients.length && 'ссылки на контрагентов',
      ]
        .filter(Boolean)
        .join(', ');

      const message = part ? `Внимание! Во время создания документа не будут добавлены ${part}.` : null;

      return new FormError(2, message);
    },
  },
  actions: {
    enable({commit, state}, payload) {
      if (state.steps[payload] === null) {
        commit('enable', payload);
      }
    },
  },
  mutations: {
    set(state, payload) {
      state.steps = payload;
    },
    enable(state, payload) {
      state.steps = {
        ...state.steps,
        [payload]: true,
      };
    },
    clear(state) {
      state.steps = INITIAL_ENABLED_STEPS;
    },
  },
};
