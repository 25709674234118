import axios from 'axios';
import FileApiService from '@/services/api/file/file-api.service';
import {saveFile} from '@/common/utils/save-file';
import store from '@/store';
import ApiFile from '@/common/models/file/api-file';

export default class DocumentFile extends ApiFile {
  constructor(response) {
    super(response);
    this.parentTableId = response['PARENT_TABLE_ID'];
    this.parentId = response['PARENT_ID'];
    this.user = response['Пользователь'];
    this.signCount = response['ЭП'];
    this.documentId = response['DOC_ID'];
    this.isLast = response['Индикатор'] > 0;
  }

  // TODO: Уточнить сценарий и вынести логику в сервис
  async download() {
    let url = `${store.state.server.storageUrl}${this.hash}.${this.extension}`;

    try {
      await axios.get(url);
      const name = `${this.documentId}_${this.revision.value}_${this.name}`;
      saveFile(url, name);
    } catch (error) {
      await FileApiService.addView(this.id);
      setTimeout(() => this.download(), 1000);
    }
  }
}
