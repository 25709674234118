import {STANDARD_ROUTE} from '@/configs/routeTypes';
import {START_NOW_CONTROL_TYPE} from '@/configs/controlTypes';
import {LOADING_MASTER_STEP_STATUS} from '@/configs/master/masterStepLoadingStatus';
import {INITIAL_START_TYPE} from '@/common/consts/document-master/common';

/** @deprecated: Используйте store */
export default {
  skip_barcode: true,
  created: false,
  clear_control_start: false,
  route_none: false,
  errors: false,
  resolution: null,
  reg_number: null,
  reg_date: null,
  route_copy_doc_id: null,
  routes_responsible_unspecified_steps: [],
  type_route: STANDARD_ROUTE,
  control_type: START_NOW_CONTROL_TYPE,
  startType: INITIAL_START_TYPE,
  loadingStatuses: {
    doc: LOADING_MASTER_STEP_STATUS,
    links: LOADING_MASTER_STEP_STATUS,
    temps: LOADING_MASTER_STEP_STATUS,
    resolution: LOADING_MASTER_STEP_STATUS,
    files: LOADING_MASTER_STEP_STATUS,
    routes: LOADING_MASTER_STEP_STATUS,
    copy_route: LOADING_MASTER_STEP_STATUS,
  },
};
