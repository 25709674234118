import StepContent from '@/components/document-master/steps/StepContent.vue';
import {StepCode} from '@/common/consts/document-master/common';

export class StepContentCreator {
  static create(title) {
    return {
      code: StepCode.Content,
      title,
      tooltip: 'Заполните форму и дополнительные характеристики для создания документа. Удобнее заполнять их, если Вы приложите файлы',
      menuTitle: 'Содержание документа',
      noWrapper: true,
      width: 'xl',
      height: '100%',
      component: StepContent,
    };
  }
}
