<template>
  <div class="file-size">
    {{ formatted }}
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {fileSize} from '@/services/utilsFunctions';

export default defineComponent({
  name: 'FileSize',
  props: {
    size: {
      type: Number,
      required: true,
    },
  },
  computed: {
    formatted() {
      return fileSize(this.size);
    },
  },
});
</script>

<style scoped lang="scss">
.file-size {
  padding: 4px;
}
</style>
