import HttpService from '@/services/api/http.service';
import {UserEndPoint} from '@/common/consts/endPoint';

export default class UserApiService {
  static async getId() {
    const {login_id} = await HttpService.post(UserEndPoint.GetId);
    return login_id;
  }

  static async getName(id) {
    const {user_fio} = await HttpService.post(UserEndPoint.GetName, {
      login_id: id,
    });
    return user_fio;
  }
}
