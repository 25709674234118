<template>
  <drop-zone-ui class="upload-ui" @drop="add">
    <input ref="input" hidden type="file" multiple @change="onChange">

    <card-list-ui
      v-if="modelValue.length"
      :list="modelValue"
      :card-footer="FileSize"
      with-add
      @add="dispatchChange"
      @remove="remove"
    ></card-list-ui>

    <div v-else class="empty" @click="dispatchChange">
      <button-ui color="black" mode="outline">
        <template #prefix>
          <upload-icon></upload-icon>
        </template>
        <span>Загрузить файлы</span>
      </button-ui>

      <p class="empty-text">Выберите и загрузите файл с компьютера или перетащите в это поле</p>
    </div>
  </drop-zone-ui>
</template>

<script>
import {defineComponent} from 'vue';
import DropZoneUi from '@/components/ui/DropZoneUi.vue';
import CardListUi from '@/components/ui/CardListUi.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import UploadIcon from '@/assets/svg/upload.svg?component';
import FileSize from '@/components/document-master/FileSize.vue';
import upload from '@/mixins/upload';

export default defineComponent({
  name: 'UploadUi',
  mixins: [upload],
  components: {
    ButtonUi,
    CardListUi,
    DropZoneUi,
    UploadIcon,
  },
  data() {
    return {
      FileSize,
    };
  },
});
</script>

<style scoped lang="scss">
.drop-zone-ui {
  overflow-y: auto;
  min-height: 120px;

  display: flex;
  justify-content: center;

  border: var(--border-dashed-gray-400);
  border-radius: 8px;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-ui {
  margin-bottom: 16px;
}

.empty-text {
  margin-bottom: 0; // TODO: Перекрытие стилей
  width: 300px;

  text-align: center;
  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
}
</style>
