export default {
  namespaced: true,
  state: {
    controller: null,
  },
  getters: {
    controllerId(state) {
      return state.controller.code;
    },
  },
  actions: {
    setUser({commit, rootState}) {
      commit('set', {code: rootState.user.userId, label: rootState.user.userName});
    },
  },
  mutations: {
    set(state, payload) {
      state.controller = payload;
    },
    clear(state) {
      state.controller = null;
    },
  },
};
