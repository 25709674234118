<template>
  <section class="document-master-wrapper">
    <document-master-steps></document-master-steps>

    <div class="content">
      <document-master-header animation="slide"></document-master-header>

      <div class="wrapper">
        <transition name="opacity" mode="out-in">
          <slot></slot>
        </transition>
      </div>

      <document-master-footer></document-master-footer>
    </div>
  </section>
</template>

<script>
import {defineComponent} from 'vue';
import DocumentMasterHeader from '@/components/document-master/wrapper/DocumentMasterHeader.vue';
import DocumentMasterSteps from '@/components/document-master/wrapper/DocumentMasterSteps.vue';
import DocumentMasterFooter from '@/components/document-master/wrapper/DocumentMasterFooter.vue';

export default defineComponent({
  name: 'DocumentMasterWrapper',
  components: {
    DocumentMasterFooter,
    DocumentMasterSteps,
    DocumentMasterHeader,
  },
});
</script>

<style scoped lang="scss">
.document-master-wrapper {
  height: 100%;
  display: flex;
}

.document-master-steps {
  position: relative;
  z-index: 1;

  flex: 0 0 210px;
  margin-right: 16px;
}

.content {
  flex: 1 1 100%;

  display: flex;
  flex-direction: column;
}

.document-master-header {
  margin-bottom: 20px;
}

.wrapper {
  flex-grow: 1;
  position: relative;

  margin-bottom: 20px;
  min-height: 0;
}
</style>
