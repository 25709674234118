<template>
  <footer v-if="!controlsDisabled" class="document-master-footer">
    <button-ui v-if="showForceClose" color="white" @click="close">
      <template #prefix>
        <circle-close-icon></circle-close-icon>
      </template>
      <span>Закрыть</span>
    </button-ui>

    <button-ui v-else-if="!isCreated" color="white" @click="cancel">
      <template #prefix>
        <circle-close-icon></circle-close-icon>
      </template>
      <span>Отмена</span>
    </button-ui>

    <button-ui v-if="!isFirstPage && !isCreated" color="black" @click="back">
      Назад
    </button-ui>

    <button-ui v-if="!isLastPage" @click="next">
      Далее
    </button-ui>

    <button-ui v-else @click="ready">
      Готово
    </button-ui>
  </footer>
</template>

<script>
import {defineComponent} from 'vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import CircleCloseIcon from '@/assets/svg/circle-close.svg?component';
import {mapActions, mapGetters, mapState} from 'vuex';

export default defineComponent({
  name: 'DocumentMasterFooter',
  components: {
    ButtonUi,
    CircleCloseIcon,
  },
  computed: {
    ...mapState('master', ['controlsDisabled', 'isCreated', 'showForceClose']),
    ...mapGetters('master', ['isFirstPage', 'isLastPage']),
  },
  methods: {
    ...mapActions('master', ['close', 'cancel', 'back', 'next', 'ready']),
  },
});
</script>

<style scoped lang="scss">
.document-master-footer {
  display: flex;
  justify-content: flex-end;
}

.button-ui {
  &:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
