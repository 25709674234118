<template>
  <modal-ui ref="modal" width="l" height="100%" prevent-closure @close="$emit('close')">
    <template #default>
      <ad-annotation
        ref="annotation"
        :file="annotationFile"
        :pages="pages"
        :initial="initial"
        :state="annotationState"
        :loader-text="loaderText"
        @fetch="$emit('fetch', $event)"
        @retry="$emit('retry', $event)"
        @download="file.download()"
        @change="$emit('change', $event)"
        @apply="$emit('apply', $event)"
        @close="$emit('close')"
      ></ad-annotation>
    </template>
  </modal-ui>
</template>

<script>
import {defineAsyncComponent, defineComponent} from 'vue';
import ModalUi from '@/components/ui/ModalUi.vue';
import DocumentFile from '@/common/models/file/document-file';
import {State} from '@/common/consts/state.consts';

export default defineComponent({
  name: 'EditFileModal',
  components: {
    ModalUi,
    AdAnnotation: defineAsyncComponent(() => import('@alphadoc/alphadoc-annotation').then(({AdAnnotation}) => AdAnnotation)),
  },
  props: {
    file: {
      type: DocumentFile,
      required: true,
    },
    pages: {
      type: Array,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
    initial: {
      type: Number,
      required: true,
    },
    sending: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['fetch', 'retry', 'change', 'apply', 'close'],
  computed: {
    // TODO: Обновить библиотеку
    annotationFile() {
      return {
        ...this.file,
        name: this.file.title,
      };
    },
    annotationState() {
      return this.sending ? State.Loading : this.state;
    },
    loaderText() {
      return this.sending ? 'Отправка данных' : 'Загрузка данных';
    },
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    getAnnotations() {
      return this.$refs.annotation.getAnnotations();
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/resolutions";

.annotation {
  height: 100%
}

/* TODO: Отказаться от перекрытия стилей */
:deep {
  .icon {
    display: block;
    width: auto;
  }

  .button {
    &._pencil {
      svg {
        fill: transparent;
      }
    }
  }

  p {
    margin-bottom: 0;
  }
}
</style>
